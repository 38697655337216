// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-bg-color:#404040;
  --table-striped-bg-color: #F5F9F2;
  --table-hover-bg-color: #E2EFDA;
  --table-header-text-color: #242529;
  --table-header-bg-color: #E2E2E2;
  --table-title-bg-color: #ffffff;
  --table-title-text-color: #64B348;
  --sidebar-bg-color: #404040;
  --sidebar-dropdown-bg-color: #808080;
  --sidebar-text-color: #eeeeee;
  --topbar-bg-color: #64B348;
  --topbar-text-color: #F2F2F2;
  --filter-choice-display-bg-color: #F5F9F2;
  --filter-choice-display-text-color: #64B348;
  --footer-bg-color :#404040;
  --footer-text-color :#ffffff;
}

@media(min-width:700px) {
  .SideBar {
    display: flex;
    position: static;
    /* flex-flow: wrap; */
    flex-direction: column;
    left: 0;
    width: 300px;
    height: 100%;
    background-color: var(--sidebar-bg-color);
    color: var(--sidebar-text-color);
    text-align: left;
    align-content: flex-start;
  } 
  .SideBarToggleButton{
    display: none;
  }
  /* .disclaimerPopup{
    width: 500px;
  } */
}`, "",{"version":3,"sources":["webpack://./src/Desktop.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iCAAiC;EACjC,+BAA+B;EAC/B,kCAAkC;EAClC,gCAAgC;EAChC,+BAA+B;EAC/B,iCAAiC;EACjC,2BAA2B;EAC3B,oCAAoC;EACpC,6BAA6B;EAC7B,0BAA0B;EAC1B,4BAA4B;EAC5B,yCAAyC;EACzC,2CAA2C;EAC3C,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;IACtB,OAAO;IACP,YAAY;IACZ,YAAY;IACZ,yCAAyC;IACzC,gCAAgC;IAChC,gBAAgB;IAChB,yBAAyB;EAC3B;EACA;IACE,aAAa;EACf;EACA;;KAEG;AACL","sourcesContent":[":root {\n  --main-bg-color:#404040;\n  --table-striped-bg-color: #F5F9F2;\n  --table-hover-bg-color: #E2EFDA;\n  --table-header-text-color: #242529;\n  --table-header-bg-color: #E2E2E2;\n  --table-title-bg-color: #ffffff;\n  --table-title-text-color: #64B348;\n  --sidebar-bg-color: #404040;\n  --sidebar-dropdown-bg-color: #808080;\n  --sidebar-text-color: #eeeeee;\n  --topbar-bg-color: #64B348;\n  --topbar-text-color: #F2F2F2;\n  --filter-choice-display-bg-color: #F5F9F2;\n  --filter-choice-display-text-color: #64B348;\n  --footer-bg-color :#404040;\n  --footer-text-color :#ffffff;\n}\n\n@media(min-width:700px) {\n  .SideBar {\n    display: flex;\n    position: static;\n    /* flex-flow: wrap; */\n    flex-direction: column;\n    left: 0;\n    width: 300px;\n    height: 100%;\n    background-color: var(--sidebar-bg-color);\n    color: var(--sidebar-text-color);\n    text-align: left;\n    align-content: flex-start;\n  } \n  .SideBarToggleButton{\n    display: none;\n  }\n  /* .disclaimerPopup{\n    width: 500px;\n  } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
