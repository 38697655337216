import { css } from "@emotion/css";
const Button = ({ children, onClick, style }) => {
	return (
		<button
			className={css`
				background-color: #404040;
				border-width: 2px;
				border-style: solid;
				border-color: #64b348;
				border-radius: 6px;
				color: white;
				font-size: 14px;
				transition: 0.3s;
				&:hover {
					background-color: #64b348;
					font-size: 14px;
					color: white;
				}
			`}
			onClick={onClick}
			style={style}
		>
			{children}
		</button>
	);
};

export default Button;
