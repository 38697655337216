import { useRef, useEffect } from "react";
import x_icon from "../assets/images/x_icon.png";
const Popup = ({ title, label, poppedUp, setPoppedUp }) => {
	//Code for detecting when you click out of the popup
	const ref = useRef(null);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target) && poppedUp) {
				setPoppedUp(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [poppedUp, setPoppedUp]);

	return (
		<div ref={ref} className="disclaimerPopup" style={poppedUp ? { display: "block" } : { display: "none" }}>
			<div className="header">
				<img
					src={x_icon}
					style={{
						position: "absolute",
						top: "12px",
						right: "12px",
						width: "26px",
						height: "26px",
					}}
					onClick={() => {
						setPoppedUp(false);
					}}
				></img>
				<span>{title}</span>
			</div>

			<br></br>
			<span>{label}</span>
		</div>
	);
};

export default Popup;
