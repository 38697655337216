import { css } from "@emotion/css";
const Item = ({
	data,
	checkable = false,
	selectedItem = () => {},
	checked,
	disabled,
	onChange,
	style,
}) => {
	if (data.customComponent) {
		return data.customComponent;
	} else {
		return (
			<div className="checkboxInput" style={style}>
				{checkable && (
					<input
						type="checkbox"
						checked={checked(data)}
						disabled={disabled(data)}
						onChange={() => {
							onChange(data);
						}}
					/>
				)}
				{!data.isInput ? (
					<span
						className={
							!checkable && !data.noInteraction
								? "button dropdownItemLabel"
								: ""
						}
						// checkable={checkable.toString()}
						style={
							!checkable && selectedItem(data)
								? { backgroundColor: "#696969" }
								: null
						}
						onClick={() => {
							if (!checkable && !data.noInteraction) {
								onChange(data);
							}
						}}
					>
						{data.label}
					</span>
				) : (
					<div>
						<span
							className={css`
								margin-right: 6px;
							`}
						>
							{data.label}
						</span>
						<input
							className={css`
								margin-bottom: 6px;
								border-radius: 6px;
								border-width: 0px;
								font-size: 14px;
								width: 30px;
							`}
							placeholder={data.label}
							onChange={(e) => {
								onChange(e, data);
							}}
							value={data.defaultValue ? data.defaultValue : ""}
						/>
					</div>
				)}

				{data.details && data.details.elements ? data.details.elements : null}
			</div>
		);
	}
};

export default Item;
