import React, { useEffect, useState } from "react";
import "../App.css";
import "../Desktop.css";
import { useMediaQuery } from "react-responsive";
import BSSIDSidebar from "../BSSIDSidebar";
import TopBar from "../TopBar";
import Table from "react-bootstrap/Table";
import { css } from "@emotion/css";

const fetchAPIKeyHolder = async (apiKey, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchAPIKeyHolder`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch API Key Holder Errors: ", data.errors[0]);
			}
			setter(data);
		});
};

const fetchOrgIDs = async (apiKey, setter) => {
	return await fetch(`https://merakiwifi.app/api/FetchOrgIDs`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Orginazation IDs Errors: ", data.errors[0]);
				// setter();
				return [data.errors[0]];
			}
			return data;
			// return data
		});
};

const fetchNetworkIDs = async (apiKey, orgID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkIDs`, {
		headers: {
			apikey: apiKey,
			organizationid: orgID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network IDs Errors: ", data.errors[0]);
				// setter([data.errors[0]]);
				return [data.errors[0]];
			}
			return data;
		});
};

const fetchOrganizationDevices = async (apiKey, orgID) => {
	return await fetch(`https://merakiwifi.app/api/FetchOrganizationDevices`, {
		headers: {
			apikey: apiKey,
			orgid: orgID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Organization Devices Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchAPConfigs = async (apiKey, devices) => {
	console.log("getting comfigs");
	let configs = {};
	for (const device of devices) {
		await fetch(`https://merakiwifi.app/api/FetchAPConfig`, {
			headers: {
				apikey: apiKey,
				deviceSerial: device.serial,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.errors) {
					console.log("Fetch Device Config Errors: ", data.errors[0]);
				}
				let ssidConfigs = {};
				for (let config of data.basicServiceSets) {
					if (ssidConfigs[config.ssidNumber]) {
						ssidConfigs[config.ssidNumber] = [
							...ssidConfigs[config.ssidNumber],
							config,
						];
					} else {
						ssidConfigs[config.ssidNumber] = [config];
					}
				}
				configs[device.serial] = ssidConfigs;
			});
	}
	// console.log("configs, ", configs);
	return configs;
};

const BSSIDDashboard = () => {
	const [apiKey, setApiKey] = useState("");
	const [apiKeyHolder, setApiKeyHolder] = useState();
	const [orgIDs, setOrgIDs] = useState([]);
	const [selectedOrgID, setSelectedOrgID] = useState(0);
	const [networkIDs, setNetworkIDs] = useState([]);
	const [selectedNetworkID, setSelectedNetworkID] = useState({});
	const [devices, setDevices] = useState({});
	const [deviceConfigs, setDeviceConfigs] = useState({});
	const [displayData, setDisplayData] = useState([]);
	const [networkSSIDs, setNetworkSSIDs] = useState([]);
	const [selectedSSIDs, setSelectedSSIDs] = useState([]);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		const getNetworkIDs = async () => {
			setNetworkIDs(await fetchNetworkIDs(apiKey, selectedOrgID));
		};

		if (apiKey && selectedOrgID !== 0) {
			getNetworkIDs();
		}
	}, [selectedOrgID]);

	useEffect(() => {
		if (typeof orgIDs !== Array && orgIDs.length === 1) {
			console.log(orgIDs);
			setSelectedOrgID(orgIDs[0].id);
		}
	}, [orgIDs]);

	useEffect(() => {
		//get all APs/Devices in the organization
		const getDevices = async () => {
			let networkDevices = {};
			let allDevices = await fetchOrganizationDevices(apiKey, selectedOrgID);
			for (let device of allDevices) {
				if (device.name.includes("MR")) {
					if (networkDevices[device.networkId]) {
						networkDevices[device.networkId] = [
							...networkDevices[device.networkId],
							device,
						];
					} else {
						networkDevices[device.networkId] = [device];
					}
				}
			}
			//Sort the devices Alphanumarically
			// for (let networkName of Object.keys(networkDevices)) {
			// 	let devices = networkDevices[networkName];
			// 	devices.sort(apNameSorter);
			// 	networkDevices[networkName] = devices;
			// }
			setDevices(networkDevices);
		};
		if (apiKey && selectedOrgID) {
			getDevices();
		}
	}, [selectedOrgID]);

	useEffect(() => {
		// console.log(devices[selectedNetworkID.id]);
		const getDeviceConfigs = async () => {
			setDeviceConfigs(
				await fetchAPConfigs(apiKey, devices[selectedNetworkID.id])
			);
		};
		if (apiKey && devices[selectedNetworkID.id]) {
			getDeviceConfigs();
		}
	}, [apiKey, devices, selectedNetworkID]);

	useEffect(() => {
		if (deviceConfigs && selectedNetworkID && devices) {
			console.log(devices);
			let newNetworkSSIDs = [];
			for (let [deviceSerial, ssidConfigSet] of Object.entries(deviceConfigs)) {
				// console.log(deviceSerial, ssidConfigSet);
				let apName = devices[selectedNetworkID.id].find(
					(device) => device.serial == deviceSerial
				).name;
				let bssids = {};
				for (let [ssidNumber, ssidConfigs] of Object.entries(ssidConfigSet)) {
					//update the ssid config data to inclue the bssid
					for (let config of ssidConfigs) {
						// console.log(config);
						if (bssids[ssidNumber]) {
							bssids[ssidNumber] = {
								...bssids[ssidNumber],
								[config.band]: config.bssid,
							};
						} else {
							bssids[ssidNumber] = { [config.band]: config.bssid };
						}
					}
					// unless there is already a ssid has already been recorded, save to new ssidnumber and config in a key value pair.
					if (
						!newNetworkSSIDs.some((ssid) => Object.keys(ssid)[0] == ssidNumber)
					) {
						console.log("adding ", ssidNumber, " to ssids");
						newNetworkSSIDs.push({ [ssidNumber]: ssidConfigs });
					}
					//update the networkSSIDs usestate variable
					setNetworkSSIDs(newNetworkSSIDs);
				}
				let formatedData = [];
				for (let ssidNumber of Object.keys(ssidConfigSet)) {
					formatedData.push({
						apName: apName,
						ssidNumber: ssidNumber,
						ssidName: ssidConfigSet[ssidNumber][0].ssidName,
						bssids: bssids[ssidNumber],
					});
				}
				setDisplayData((current) => [...current, ...formatedData]);
			}
		}
	}, [deviceConfigs, selectedNetworkID]);

	useEffect(() => {
		// setDevices({});
		// setDeviceConfigs({});
		setDisplayData([]);
	}, [selectedNetworkID]);

	return (
		<div className="App">
			<span
				onClick={() => {
					setSidebarOpen(!sidebarOpen);
				}}
				className="SideBarToggleButton"
			>
				≡
			</span>
			<BSSIDSidebar
				orgIDs={orgIDs}
				selectedOrgID={selectedOrgID}
				setSelectedOrgID={setSelectedOrgID}
				networkIDs={networkIDs}
				selectedNetworkID={selectedNetworkID}
				setSelectedNetworkID={setSelectedNetworkID}
				networkSSIDs={networkSSIDs}
				selectedSSIDs={selectedSSIDs}
				setSelectedSSIDs={setSelectedSSIDs}
				sidebarOpen={sidebarOpen}
			/>
			<div className="MainPanel">
				<TopBar
					fetchOrgIDs={fetchOrgIDs}
					setOrgIDs={setOrgIDs}
					fetchAPIKeyHolder={fetchAPIKeyHolder}
					apiKeyHolder={apiKeyHolder}
					setApiKeyHolder={setApiKeyHolder}
					apiKey={apiKey}
					setApiKey={setApiKey}
				></TopBar>
				<div className="BSSIDTable">
					<Table>
						<thead>
							<tr>
								<th>
									<span>AP Name</span>
								</th>
								<th>
									<span>SSID</span>
								</th>
								<th>
									<span>BSSID 2.4 GHz</span>
								</th>
								<th>
									<span>BSSID 5 GHz</span>
								</th>
								<th>
									<span>BSSID 6 GHz</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{displayData.map((dataSet, i) => {
								console.log(dataSet);
								// console.log(dataSet);
								if (selectedSSIDs.length) {
									if (selectedSSIDs.includes(parseInt(dataSet.ssidNumber))) {
										return (
											<tr key={i}>
												<td>{dataSet.apName}</td>
												<td>{dataSet.ssidName}</td>
												<td>
													{dataSet.bssids["2.4 GHz"] &&
														dataSet.bssids["2.4 GHz"]}
												</td>
												<td>
													{dataSet.bssids["5 GHz"] && dataSet.bssids["5 GHz"]}
												</td>
												<td>
													{dataSet.bssids["6 GHz"] && dataSet.bssids["6 GHz"]}
												</td>
											</tr>
										);
									} else {
										return;
									}
								} else {
									// console.log(dataSet);
									return (
										<tr key={i}>
											<td>{dataSet.apName}</td>
											<td>{dataSet.ssidName}</td>
											<td>
												{dataSet.bssids["2.4 GHz"] && dataSet.bssids["2.4 GHz"]}
											</td>
											<td>
												{dataSet.bssids["5 GHz"] && dataSet.bssids["5 GHz"]}
											</td>
											<td>
												{dataSet.bssids["6 GHz"] && dataSet.bssids["6 GHz"]}
											</td>
										</tr>
									);
								}
							})}
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default BSSIDDashboard;
