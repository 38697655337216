import React, { useEffect, useState } from "react";
import "../App.css";
import "../Desktop.css";
import DebugDashboardSideBar from "../DebugDashboardSideBar.js";
import TopBar from "../TopBar.js";
import NetworkEventsTable from "../NetworkEventsTable";
import APConfigTable from "../APConfigTable";
import SSIDConfigTable from "../SSIDConfigTable";
import Disclaimer from "../Disclaimer";
import kofi_tip_icon from "../assets/images/kofi_tip_icon.png";
import venmo_tip_icon from "../assets/images/venmo_tip_icon.png";
import linkedin_icon from "../assets/images/linkedin_icon.png";
// import Button from "../Button";
// import { css } from "@emotion/css";
import { useMediaQuery } from "react-responsive";

const REQUEST_BIN_URL = "https://enk95mcowe2m.x.pipedream.net";

const fetchAPIKeyHolder = async (apiKey, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchAPIKeyHolder`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch API Key Holder Errors: ", data.errors);
				// alert("Fetch API Key Holder Errors: " + data.errors);
			}
			setter(data);
		});
};

const fetchOrgIDs = async (apiKey) => {
	return await fetch(`https://merakiwifi.app/api/FetchOrgIDs`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Orginazation IDs Errors: ", data.errors[0]);
				// setter([data.errors[0]]);
				return [data.errors[0]];
			}
			// console.log(data);
			// setter(data);
			return data;
			// return data
		});
};

const fetchDeviceStatuses = async (apiKey, orgID, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchDeviceStatuses`, {
		headers: {
			apikey: apiKey,
			organizationid: orgID,
			networkid: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Device Statuses Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchNetworkIDs = async (apiKey, orgID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkIDs`, {
		headers: {
			apikey: apiKey,
			organizationid: orgID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network IDs Errors: ", data.errors[0]);
				// setter([data.errors[0]]);
				return [data.errors[0]];
			}
			return data;
		});
};

const fetchNetworkClients = async (apiKey, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkClients`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Clients Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchNetworkDevices = async (apiKey, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkDevices`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Devices Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchSwitchPortNumbers = async (apiKey, devices) => {
	let portNumbers = [];
	for (const device of devices) {
		if (device.model.includes("MS")) {
			let data = await fetch(
				`https://merakiwifi.app/api/FetchSwitchPortNumber`,
				{
					headers: {
						apikey: apiKey,
						switchSerial: device.serial,
					},
				}
			).then((response) => response.json());
			if (data.errors) {
				console.log("Fetch Switch Port Numbers Errors: ", data.errors[0]);
			}
			// console.log(data);
			if (data && data.ports) {
				// console.log(data);
				portNumbers = portNumbers.concat(
					Object.keys(data.ports)
						.map((port) => {
							if (data.ports[port].lldp && data.ports[port].lldp.systemName) {
								let ap = devices.find((lookup) =>
									data.ports[port].lldp.systemName.includes(lookup.name)
								);
								if (ap) {
									return {
										switchName: device.name,
										apName: ap.name,
										portNumber: data.ports[port].lldp.sourcePort,
									};
								}
							}
						})
						.filter((lookup) => lookup)
				);
			}
		}
	}
	// console.log("welp:", portNumbers);
	return portNumbers;
};

const fetchNetworkHealth = async (apiKey, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkHealth`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Health Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchNetworkWirelessConnectionStats = async (
	apiKey,
	networkId,
	ssids
) => {
	let stats = [];
	for (const ssid of ssids) {
		const data = await fetch(
			`https://merakiwifi.app/api/FetchNetworkWirelessConnectionStats`,
			{
				headers: {
					apikey: apiKey,
					networkid: networkId,
					ssidnumber: ssid.number,
				},
			}
		).then((response) => response.json());
		if (data.errors) {
			console.log(
				"Fetch Network Wireless Connection Stats Errors: ",
				data.errors[0]
			);
		}
		stats.push({ stats: data, number: ssid.number });
	}
	return stats;
};

const fetchDeviceConnectionStats = async (apiKey, networkId) => {
	return await fetch(`https://merakiwifi.app/api/FetchDeviceConnectionStats`, {
		headers: {
			apikey: apiKey,
			networkid: networkId,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log(
					"Fetch Network Devices Connection Stats Errors: ",
					data.errors[0]
				);
			}
			return data;
		});
};

const fetchDeviceClientCounts = async (apiKey, networkID, devices) => {
	let clients = [];
	for (const device of devices) {
		if (["MR", "CW"].includes(device.model.slice(0, 2))) {
			const data = await fetch(`https://merakiwifi.app/api/FetchClientCount`, {
				headers: {
					apikey: apiKey,
					networkID: networkID,
					deviceSerial: device.serial,
				},
			}).then((response) => response.json());
			if (data.errors) {
				console.log("Fetch Device Client Counts Errors: ", data.errors[0]);
			}
			clients.push({
				mac: device.mac,
				clientCount: data[0].clientCount ? data[0].clientCount : 0,
			});
		}
	}
	// devices.map((device) => {});
	// console.log("set client counts!", JSON.stringify(clients, null, 2));
	return clients;
};

const fetchSSIDClientCounts = async (apiKey, networkID, ssids) => {
	let clients = [];
	for (const ssid of ssids) {
		const data = await fetch(`https://merakiwifi.app/api/FetchClientCount`, {
			headers: {
				apikey: apiKey,
				networkID: networkID,
				ssidnumber: ssid.number,
			},
		}).then((response) => response.json());
		if (data.errors) {
			console.log("Fetch SSID Client Counts Errors: ", data.errors[0]);
		}
		clients.push({
			number: ssid.number,
			clientCount: data[0].clientCount || 0,
		});
	}
	// console.log("ssid Client count data: ", clients);
	// devices.map((device) => {});
	// console.log("set client counts!", JSON.stringify(clients, null, 2));
	return clients;
};

const fetchAPConfig = async (
	apiKey,
	devices,
	switchPortNumbers,
	networkHealth,
	deviceConnectionStats,
	deviceClientCounts,
	deviceStatuses
) => {
	console.log("Starting AP config fetch");
	let configs = [];
	// console.log(`NetworkHealth:`, networkHealth);
	for (const device of devices) {
		// console.log(device)
		if (
			(device.model.includes("MR") || device.model.includes("CW")) &&
			deviceStatuses.find((deviceStatus) => deviceStatus.mac === device.mac) &&
			deviceStatuses.find((deviceStatus) => deviceStatus.mac === device.mac)
				.status !== "dormant"
		) {
			console.log("fetching Ap configs");
			const data = await fetch(`https://merakiwifi.app/api/FetchAPConfig`, {
				headers: {
					apikey: apiKey,
					deviceSerial: device.serial,
				},
			}).then((response) => response.json());
			if (data.errors) {
				console.log("Fetch AP Config Errors: ", data.errors[0]);
				return;
			}
			// console.log(deviceConnectionStats);
			let connectivityIssues = "";
			if (deviceConnectionStats.length) {
				if (
					deviceConnectionStats.some(
						(deviceStats) => deviceStats.serial === device.serial
					)
				) {
					let deviceStats = deviceConnectionStats.find(
						(deviceStats) => deviceStats.serial === device.serial
					).connectionStats;
					// console.log(deviceStats)
					connectivityIssues = deviceStats;
					// deviceStats.assoc +
					// deviceStats.auth +
					// deviceStats.dhcp +
					// deviceStats.dns;
				}
			}
			let utilization = [];
			if (networkHealth) {
				let deviceHealth = networkHealth.find(
					(lookup) => lookup.serial === device.serial
				);
				// console.log(networkHealth, device);
				if (deviceHealth) {
					for (const el of Object.keys(deviceHealth)) {
						if (el.includes("wifi") && deviceHealth[el]["0"]) {
							utilization.push(
								Math.floor(
									parseFloat(deviceHealth[el]["0"].utilization)
								).toString() + "%"
							);
						}
					}
				}
			}
			let clientCount = 0;
			if (
				deviceClientCounts.length &&
				deviceClientCounts.some(
					(deviceClient) => deviceClient.mac === device.mac
				)
			) {
				clientCount = deviceClientCounts.find(
					(deviceClient) => deviceClient.mac === device.mac
				).clientCount;
			}
			let deviceStatus;
			if (deviceStatuses.length) {
				deviceStatus = deviceStatuses.find(
					(deviceStatus) => deviceStatus.mac === device.mac
				).status;
			}
			let switchAndPortData;
			// console.log("switchport: ", switchPort);
			switchAndPortData = switchPortNumbers.find(
				(lookup) => lookup.apName === device.name
			);
			// if (switchAndPortData) {
			// 	console.log(`spn:`, switchPortNumbers);
			// }

			// console.log(switchAndPortData);
			//For each ssid, test if we have a power associated with the band value, and if we dont, add one to the "power" object.
			let power = {};
			for (const ssid of data.basicServiceSets) {
				if (!power[ssid.band]) {
					power[ssid.band] = ssid.power;
				}
			}

			configs.push({
				utilization: utilization.join(", "),
				deviceConnectivityIssues: connectivityIssues,
				deviceClientCount: clientCount,
				deviceStatus: deviceStatus,
				deviceMac: device.mac,
				deviceName: device.name,
				deviceModel: device.model,
				deviceFirmware: device.firmware,
				switchAndPort: switchAndPortData,
				channels: [
					...new Set(
						data.basicServiceSets.map((ssid) => {
							return ssid.channel;
						})
					),
				].join(", "),
				channelWidths: [
					...new Set(
						data.basicServiceSets.map((ssid) => {
							return ssid.channelWidth;
						})
					),
				].join(", "),
				power: Object.values(power).join(", "),
			});
		}
		// else {
		// 	console.log("failed fetching configs", device, deviceStatuses);
		// }
	}
	return configs;
};

const fetchNetworkWirelessSsids = async (apiKey, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkWirelessSsids`, {
		headers: {
			apikey: apiKey,
			networkid: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Wireless Ssids Errors: ", data.errors[0]);
				alert("Fetch Network Wireless Ssids Errors: " + data.errors[0]);
				// setter(data.errors);
				return data.errors;
			}

			return data.filter((ssid) => (ssid.enabled ? ssid : null));
		});
};

const fetchNetworkEventTypes = async (apiKey, networkID) => {
	return await fetch(`https://merakiwifi.app/api/FetchNetworkEventTypes`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Event Types Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchNetworkEvents = async (
	apiKey,
	networkID,
	queryParams,
	customFilters
) => {
	// console.log("params!:", queryParams.startingAfter);
	return await fetch(`https://merakiwifi.app/api/FetchNetworkEvents`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
			queryParams: JSON.stringify(queryParams),
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Events Errors: ", data.errors[0]);
				// setter(data);
			} else {
				return filterNetworkEvents(data, customFilters);
			}
		});
};

const filterNetworkEvents = (networkEvents, selectedCustomFilters) => {
	let filterNetworkEvents = { ...networkEvents };
	filterNetworkEvents.events = [];
	if (selectedCustomFilters.length) {
		filterNetworkEvents.events = networkEvents.events.filter((networkEvent) =>
			selectedCustomFilters.every(
				(filter) =>
					selectedCustomFilters.some(
						(lookup) =>
							lookup.split(/:(.*)/s)[1] ===
							networkEvent[filter.split(/:(.*)/s)[0]]
					) ||
					selectedCustomFilters.some(
						(lookup) =>
							lookup.split(/:(.*)/s)[1] ===
							networkEvent.eventData[filter.split(/:(.*)/s)[0]]
					) ||
					(filter.split(/:(.*)/s)[0] === "eventLogEndTime" &&
						new Date(networkEvent.occurredAt) <
							new Date(filter.split(/:(.*)/s)[1]))
			)
		);

		// networkEvents.events.forEach((element) => {
		// 	// console.log(element)
		// 	// For each Network Event
		// 	// Test if any Custom filter apllies to said event, if so return true

		// 	// if you have an SSID selected then return all the events with the same SSID(s)
		// 	// if you have both an SSID selected and clientMac selected then return all the events with said clientMac(s) that also have the same SSID(s)
		// 	// if you just have a clientMac selected then return all events with the same clientMac
		// 	selectedCustomFilters.forEach((filter) => {
		// 		// console.log(filter)
		// 		let filterType = filter.split(/:(.*)/s)[0];

		// 		//for the Client Mac
		// 		// [...selectedCustomFilters.map(filter => filter.split(/:(.*)/s)[1])]
		// 		if (filterType === "clientMac" && filter.split(/:(.*)/s)[1] === element[filter.split(/:(.*)/s)[0]]) {
		// 			//if there is any filter for SSIDs then only return the event if the event.ssidName matches the filtering SSID
		// 			if (selectedCustomFilters.some((filter) => filter.split(":")[0] === "ssidName")) {
		// 				if ([...selectedCustomFilters.map((filter) => filter.split(/:(.*)/s)[1])].includes(element.ssidName)) {
		// 					filterNetworkEvents.events.push(element);
		// 				}
		// 			} else if (element.clientMac === filter.split(/:(.*)/s)[1]) {
		// 				filterNetworkEvents.events.push(element);
		// 			}
		// 		} else if (filterType === "ssidName" && filter.split(/:(.*)/s)[1] === element[filter.split(/:(.*)/s)[0]]) {
		// 			if (selectedCustomFilters.some((filter) => filter.split(":")[0] === "clientMac")) {
		// 				if ([...selectedCustomFilters.map((filter) => filter.split(/:(.*)/s)[1])].includes(element.clientMac)) {
		// 					filterNetworkEvents.events.push(element);
		// 				}
		// 			} else {
		// 				filterNetworkEvents.events.push(element);
		// 			}
		// 		} else if (filter.split(/:(.*)/s)[1] === element[filter.split(/:(.*)/s)[0]]) {
		// 			filterNetworkEvents.events.push(element);
		// 		} else if (filter.split(/:(.*)/s)[1] === element.eventData[filter.split(/:(.*)/s)[0]]) {
		// 			filterNetworkEvents.events.push(element);
		// 		} else if (filter.split(/:(.*)/s)[0] === "eventLogEndTime" && new Date(element.occurredAt) < new Date(filter.split(/:(.*)/s)[1])) {
		// 			filterNetworkEvents.events.push(element);
		// 		}
		// 	});
		// });
		return filterNetworkEvents;
	} else {
		return networkEvents;
	}
};
// const formatNetworkEvents = (networkEvents) => {
// 	let formatedNetworkEvents = { ...networkEvents };
// 	formatedNetworkEvents.events = formatedNetworkEvents.events.map((networkEvent) => {
// 		networkEvent["eventData"] = Object.entries(networkEvent["eventData"])
// 			.map((entry) => entry[0] + ":" + entry[1])
// 			.join(", ");
// 		return networkEvent;
// 	});
// 	return formatedNetworkEvents;
// };

function WifiDebugDashboard() {
	const [apiKey, setApiKey] = useState("");
	const [apiKeyHolder, setApiKeyHolder] = useState();
	const [orgIDs, setOrgIDs] = useState([]);
	const [selectedOrgID, setSelectedOrgID] = useState(0);
	const [networkIDs, setNetworkIDs] = useState([]);
	const [networkID, setNetworkID] = useState();
	const [networkClients, setNetworkClients] = useState([]);
	const [networkDevices, setNetworkDevices] = useState([]);
	const [switchPortNumbers, setSwitchPortNumbers] = useState([]);
	const [networkHealth, setNetworkHealth] = useState([]);
	const [ssidClientCounts, setSsidClientCounts] = useState([]);
	const [deviceStatuses, setDeviceStatuses] = useState([]);
	const [deviceClientCounts, setDeviceClientCounts] = useState([]);
	const [deviceConnectionStats, setDeviceConnectionStats] = useState([]);
	const [networkWirelessConnectionStats, setNetworkWirelessConnectionStats] =
		useState([]);
	const [ssidConfigData, setSsidConfigData] = useState([]);
	const [networkAPConfigs, setNetworkAPConfigs] = useState([]);
	const [selectedNetworkClients, setSelectedNetworkClients] = useState([]);
	const [networkWirelessSsids, setNetworkWirelessSsids] = useState([]);
	const [networkEventTypes, setNetworkEventTypes] = useState([]);
	const [
		selectedIncludedNetworkEventTypes,
		setSelectedIncludedNetworkEventTypes,
	] = useState([]);
	const [
		selectedExcludedNetworkEventTypes,
		setSelectedExcludedNetworkEventTypes,
	] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedCustomFilters, setSelectedCustomFilters] = useState([]);
	const [networkEvents, setNetworkEvents] = useState([]);
	const [queryParams, setQueryParams] = useState({});
	const [csvData, setCsvData] = useState([]);
	const [ssidTableCollapsed, setSsidTableCollapsed] = useState(false);
	const [apTableCollapsed, setApTableCollapsed] = useState(false);
	const [timezone, setTimezone] = useState();
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		let newCsvData = [];
		if (networkEvents.message === null) {
			networkEvents.events.map((networkEvent) => {
				newCsvData.push([
					networkEvent.occurredAt,
					networkEvent.deviceName,
					networkEvent.ssidName,
					networkEvent.clientDescription,
					networkEvent.clientMac,
					networkEvent.category,
					networkEvent.type,
					Object.keys(networkEvent.eventData)
						.map(function (key) {
							return key + ":" + networkEvent.eventData[key];
						})
						.join(" "),
				]);
			});
			// console.log(
			// 	new Date(networkEvents.events.slice(-1)[0].occurredAt),
			// 	new Date(queryParams.startingAfter),
			// 	Date.parse(networkEvents.events.slice(-1)[0].occurredAt) >
			// 		Date.parse(queryParams.startingAfter)
			// );
		}
		setCsvData([
			[
				"Date/Time",
				"Access Point",
				"SSID",
				"Client Name",
				"Mac Address",
				"Event Category",
				"Event Type",
				"Details",
			],
			...newCsvData,
		]);
	}, [networkEvents]);

	useEffect(() => {
		setApiKeyHolder();
		setOrgIDs([]);
		setSelectedOrgID(0);
		setNetworkIDs([]);
		setNetworkID();
		setNetworkWirelessSsids([]);
	}, [apiKey]);

	const formatSsidConfigData = () => {
		// console.log(
		// 	"ssidClientCounts",
		// 	ssidClientCounts,
		// 	"networkWirelessSsids",
		// 	networkWirelessSsids,
		// 	"networkWirelessConnectionStats",
		// 	networkWirelessConnectionStats
		// );
		if (ssidClientCounts.length >= networkWirelessSsids.length) {
			return networkWirelessSsids.map((ssid) => {
				let ssidStats = networkWirelessConnectionStats.find(
					(lookup) => lookup.number === ssid.number
				);
				let clientCount = ssidClientCounts.find(
					(lookup) => lookup.number === ssid.number
				);
				if (!(clientCount && ssidStats)) {
					return [];
				}
				return {
					...ssid,
					clientCount: ssidClientCounts.find(
						(lookup) => lookup.number === ssid.number
					).clientCount,
					stats: ssidStats.stats,
				};
			});
		} else {
			// console.log(ssidClientCounts, networkWirelessSsids);
			return [];
		}
	};

	useEffect(() => {
		const getConfigs = async () => {
			setSsidConfigData(await formatSsidConfigData());
		};
		getConfigs();
	}, [networkWirelessSsids, ssidClientCounts, networkWirelessConnectionStats]);
	return (
		<div className="App">
			{/* <link rel="icon" href={app_logo_2}></link> */}
			<span
				onClick={() => {
					setSidebarOpen(!sidebarOpen);
				}}
				className="SideBarToggleButton"
			>
				≡
			</span>
			<DebugDashboardSideBar
				timezone={timezone}
				setTimezone={setTimezone}
				fetchNetworkWirelessConnectionStats={
					fetchNetworkWirelessConnectionStats
				}
				networkWirelessConnectionStats={networkWirelessConnectionStats}
				setNetworkWirelessConnectionStats={setNetworkWirelessConnectionStats}
				fetchSwitchPortNumbers={fetchSwitchPortNumbers}
				switchPortNumbers={switchPortNumbers}
				setSwitchPortNumbers={setSwitchPortNumbers}
				fetchNetworkHealth={fetchNetworkHealth}
				networkHealth={networkHealth}
				setNetworkHealth={setNetworkHealth}
				fetchDeviceConnectionStats={fetchDeviceConnectionStats}
				deviceConnectionStats={deviceConnectionStats}
				setDeviceConnectionStats={setDeviceConnectionStats}
				fetchSSIDClientCounts={fetchSSIDClientCounts}
				ssidClientCounts={ssidClientCounts}
				setSsidClientCounts={setSsidClientCounts}
				fetchDeviceClientCounts={fetchDeviceClientCounts}
				deviceClientCounts={deviceClientCounts}
				setDeviceClientCounts={setDeviceClientCounts}
				fetchDeviceStatuses={fetchDeviceStatuses}
				deviceStatuses={deviceStatuses}
				setDeviceStatuses={setDeviceStatuses}
				apiKey={apiKey}
				setApiKey={setApiKey}
				fetchAPIKeyHolder={fetchAPIKeyHolder}
				apiKeyHolder={apiKeyHolder}
				setApiKeyHolder={setApiKeyHolder}
				fetchOrgIDs={fetchOrgIDs}
				orgIDs={orgIDs}
				setOrgIDs={setOrgIDs}
				selectedOrgID={selectedOrgID}
				setSelectedOrgID={setSelectedOrgID}
				fetchNetworkIDs={fetchNetworkIDs}
				networkIDs={networkIDs}
				setNetworkIDs={setNetworkIDs}
				networkID={networkID}
				setNetworkID={setNetworkID}
				fetchNetworkClients={fetchNetworkClients}
				networkClients={networkClients}
				setNetworkClients={setNetworkClients}
				selectedNetworkClients={selectedNetworkClients}
				setSelectedNetworkClients={setSelectedNetworkClients}
				fetchNetworkWirelessSsids={fetchNetworkWirelessSsids}
				networkWirelessSsids={networkWirelessSsids}
				setNetworkWirelessSsids={setNetworkWirelessSsids}
				fetchNetworkEventTypes={fetchNetworkEventTypes}
				networkEventTypes={networkEventTypes}
				setNetworkEventTypes={setNetworkEventTypes}
				selectedIncludedNetworkEventTypes={selectedIncludedNetworkEventTypes}
				setSelectedIncludedNetworkEventTypes={
					setSelectedIncludedNetworkEventTypes
				}
				selectedExcludedNetworkEventTypes={selectedExcludedNetworkEventTypes}
				setSelectedExcludedNetworkEventTypes={
					setSelectedExcludedNetworkEventTypes
				}
				fetchNetworkDevices={fetchNetworkDevices}
				networkDevices={networkDevices}
				setNetworkDevices={setNetworkDevices}
				// getSsidConfigData={getSsidConfigData}
				// setSsidConfigData={setSsidConfigData}
				fetchAPConfig={fetchAPConfig}
				networkAPConfigs={networkAPConfigs}
				setNetworkAPConfigs={setNetworkAPConfigs}
				customFilters={customFilters}
				setCustomFilters={setCustomFilters}
				selectedCustomFilters={selectedCustomFilters}
				setSelectedCustomFilters={setSelectedCustomFilters}
				queryParams={queryParams}
				setQueryParams={setQueryParams}
				fetchNetworkEvents={fetchNetworkEvents}
				setNetworkEvents={setNetworkEvents}
				sidebarOpen={sidebarOpen}
			/>
			<div className="MainPanel">
				<TopBar
					fetchOrgIDs={fetchOrgIDs}
					setOrgIDs={setOrgIDs}
					apiKey={apiKey}
					setApiKey={setApiKey}
					fetchAPIKeyHolder={fetchAPIKeyHolder}
					apiKeyHolder={apiKeyHolder}
					setApiKeyHolder={setApiKeyHolder}
				></TopBar>
				<div className="ConfigTableHolder">
					<APConfigTable
						apConfigData={networkAPConfigs}
						collapsed={apTableCollapsed}
						setCollapsed={setApTableCollapsed}
					></APConfigTable>
					<SSIDConfigTable
						ssidConfigData={ssidConfigData}
						collapsed={ssidTableCollapsed}
						setCollapsed={setSsidTableCollapsed}
					></SSIDConfigTable>
				</div>
				<hr
					style={
						ssidTableCollapsed
							? { height: "2px", margin: "0px" }
							: { display: "none" }
					}
				/>
				<NetworkEventsTable
					networkEvents={networkEvents}
					csvData={csvData}
					timezoneOffset={
						(timezone && timezone.offset) || -new Date().getTimezoneOffset()
					}
				></NetworkEventsTable>
				{!useMediaQuery({ query: "(max-width: 700px)" }) && (
					<div className="Footer">
						<Disclaimer
							label={
								<a href="#" style={{ color: "white" }}>
									About
								</a>
							}
							title="About:"
							popupLabel={
								<div>
									<span>
										As a Wi-Fi network engineer who's debugged a lot of Meraki
										Wi-Fi over the years, I've always wanted a way to see the
										pertinent Wi-Fi related configs and the event logs on the
										same screen. And when my son graduated from high school this
										year and was looking for a software development job for the
										summer, I thought "perfect opportunity." This has been a lot
										of fun to work on together, and it amazes me what my
										18-year-old son is capable of. We hope you find this site
										useful.
									</span>
									<div
										style={{ display: "flex", justifyContent: "space-around" }}
									>
										<a
											href="https://www.linkedin.com/in/todd-smith-wifi/"
											target="_blank"
										>
											<img
												src={linkedin_icon}
												alt=""
												style={{
													width: "40px",
													height: "40px",
													marginRight: "6px",
												}}
											/>
											Todd Smith
										</a>

										<a
											href="https://www.linkedin.com/in/asher-smith-6baa3a220/"
											target="_blank"
										>
											<img
												src={linkedin_icon}
												alt=""
												style={{
													width: "40px",
													height: "40px",
													marginRight: "6px",
												}}
											/>
											Asher Smith
										</a>
									</div>
									<div className="LeaveATip_prompt">
										<div>
											<a href="https://ko-fi.com/merakiwifiapp" target="_blank">
												<img
													src={kofi_tip_icon}
													alt=""
													style={{ width: "100%" }}
												/>
											</a>
											<a
												href="https://venmo.com/todd-smith-999"
												target="_blank"
											>
												<img
													src={venmo_tip_icon}
													alt=""
													style={{ width: "100%" }}
												/>
											</a>
										</div>
										<span>
											Tips go towards continued support and new feature
											development. Thanks!
										</span>
									</div>
								</div>
							}
						/>
						|
						<Disclaimer
							label={
								<a href="#" style={{ color: "white" }}>
									Contact
								</a>
							}
							title="Contact:"
							popupLabel={
								<span>
									If you have any questions, comments, suggestions, or feature
									requests, please email. Email:{" "}
									<a href="mailto: todd@wifi-fu.com" target="_blank">
										todd@wifi-fu.com
									</a>
								</span>
							}
						/>
						|
						<Disclaimer
							label={
								<a href="#" style={{ color: "white" }}>
									Terms of Use
								</a>
							}
							title="Terms of Use:"
							popupLabel={
								<ul>
									<li>
										<span>
											<strong>Privacy Policy:</strong> No information is stored
											outside of the running instance of this Site. Although
											information is not stored, we do not guarantee that
											Internet transmissions will always be completely private
											or secure. This Site relies upon the Cisco Meraki
											Dashboard API for retrieving a Meraki Network. Access
											granted to the Meraki network is governed by the API key
											created by the administrator of the given network.
										</span>
									</li>
									<li>
										<span>
											<strong>Terms of Use:</strong> The following terms and
											conditions (“Terms of Use”) govern your use of this Site.
											BY USING THE SITE IN ANY WAY, YOU ACKNOWLEDGE THAT YOU
											HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS
											OF USE. IF YOU DO NOT AGREE, DO NOT USE THE SITE. This
											Site is free for public use with no guarantees or official
											support. We reserve the right, in our sole discretion and
											without any liability, to make changes to the Site and any
											Site Content, wholly or partly, at any time without prior
											notice. We make no commitment to update the Site regularly
											and therefore some Site Content may be out of date.
										</span>
									</li>
									<li>
										<span>
											<strong>Usage Restrictions:</strong>You must comply with
											all applicable laws when using the Site. Except as may be
											expressly permitted by applicable law or expressly
											permitted by us in writing, you will not, and will not
											permit anyone else to: (a) store, copy, modify,
											distribute, or resell any Site Content or compile or
											collect any Site Content as part of a database or other
											work; (b) use any automated tool to use the Site or store,
											copy, modify, distribute, or resell any Site Content; (c)
											use the Site and Site Content for any purpose except as
											agreed to in these Terms of Use or upload any User Content
											in any manner that may infringe any intellectual property
											right, proprietary right, or property right of us or any
											third parties; (d) circumvent or disable any digital
											rights management, usage rules, or other security features
											of the Site; (e) reproduce, modify, translate, enhance,
											decompile, disassemble, reverse engineer, or create
											derivative works of the Site or Site Content; (f) use the
											Site in a manner that threatens the integrity,
											performance, or availability of the Site; or (g) remove,
											alter, or obscure any portion of the Site or Site Content.
										</span>
									</li>
									<li>
										<span>
											<strong>Disclaimer of Warranties:</strong> YOUR USE OF THE
											SITE, THE SITE CONTENT, INCLUDING ANY SUBMISSION BY YOU OR
											ANYONE USING YOUR API KEY(S), AND RESULTS YOU RECEIVE FROM
											THE SITE, IS AT YOUR SOLE DISCRETION AND RISK. THE SITE
											AND SITE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS
											AVAILABLE” BASIS. WE DO NOT GUARANTEE THE ACCURACY,
											COMPLETENESS, OR USEFULNESS OF THE SITE OR SITE CONTENT,
											OR THAT THE SITE WILL BE UNINTERRUPTED, ERROR-FREE,
											VIRUS-FREE, SECURE, TIMELY OR THAT ANY DEFECT WILL BE
											CORRECTED. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
											TO YOUR COMPUTER, COMUTPER NETWORK, OR LOSS OF DATA THAT
											RESULTS FROM USE OF THE SITE. YOUR SOLE REMEDY FOR ANY
											DISSATISFACTION WITH THE SITE OR ANY SITE CONTENT IS TO
											CEASE USING THE SITE OR THE SITE CONTENT.
										</span>
									</li>
									<li>
										<span>
											<strong>Limitation of Liability:</strong> IN NO EVENT
											SHALL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY
											INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR
											EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
											FOR LOSS OF PROFITS OR REVENUES, BUSINESS INTERRUPTION,
											GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING
											FROM YOUR USE AND ACCESS OF THE SITE AND SITE CONTENT, OR
											DAMAGES RELATED TO YOUR RELIANCE ON ANY INFORMATION
											OBTAINED FROM THE SITE. THIS SECTION APPLIES WHETHER
											DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT, STATUTE, OR
											ANY OTHER LEGAL THEORY.
										</span>
									</li>
								</ul>
							}
						/>
						|
						<Disclaimer
							label={
								<a href="#" style={{ color: "white" }}>
									Non-Affiliation Disclaimer
								</a>
							}
							title="Non-Affiliation Disclaimer:"
							popupLabel={
								<span>
									This site is not affiliated, associated, authorized, endorsed
									by, or in any way officially connected with Cisco Meraki. The
									use of any trade name or trademark is for identification and
									reference purposes only and does not imply any association
									with the trademark holder of their product brand.
								</span>
							}
						/>
						{/* |
						<Disclaimer
							label={
								<a href="#" style={{ color: "white" }}>
									Tip Us
								</a>
							}
							title={"Finding this site useful? Leave a tip:"}
							popupLabel={
								<div className="LeaveATip_prompt">
									<div>
										<a href="https://ko-fi.com/merakiwifiapp" target="_blank">
											<img
												src={kofi_tip_icon}
												alt=""
												style={{ width: "100%" }}
											/>
										</a>
										<a href="https://venmo.com/todd-smith-999" target="_blank">
											<img
												src={venmo_tip_icon}
												alt=""
												style={{ width: "100%" }}
											/>
										</a>
									</div>
									<span>
										Tips go towards continued support and new feature
										development. Thanks!
									</span>
								</div>
							}
						/> */}
					</div>
				)}
			</div>
		</div>
	);
}

export default WifiDebugDashboard;
