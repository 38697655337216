import { useState } from "react";

const Item = ({ data, isDict, collapsible, maxListLength = 2 }) => {
	// {
	// 	if(data.isDict){
	// 		console.log(data.label)
	// 	}
	// }
	const [expanded, setExpanded] = useState(false);
	// let text = "";
	// if (!isDict) {
	// 	text = data.label;
	// } else if (Object.keys(data.label).length) {
	// 	if (expanded) {
	// 		text = Object.entries(data.label)
	// 			.map((entry) => entry[0] + ":" + entry[1])
	// 			.join(", ");
	// 	} else {
	// 		text = Object.entries(data.label)[0].join(":");
	// 	}
	// } else text = "";
	return (
		<td>
			<div className="CollapsibleDataHolder">
				<span collapsed={collapsible && expanded ? "false" : "true"}>{collapsible ? (expanded ? data : data.slice(0, 12)) : data}</span>
				{collapsible && data.length ? (
					<a
						href="#"
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						{!expanded ? " more..." : " less..."}
					</a>
				) : null}
			</div>
		</td>
	);
};

export default Item;
