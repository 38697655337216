import { useState } from "react";
import arrow_icon from "../assets/images/arrow_icon.png";
import Category from "./Category";
import Item from "./Item";
import SelectionDisplayer from "./SelectionDisplayer";
import { css } from "@emotion/css";
import { useMediaQuery } from "react-responsive";
// import 'bootstrap/dist/css/bootstrap.css';

//scope:
// A dropdown menu that contains a list of selectable elements in a few formats
// Also contains a filterDisplay

const Dropdown = ({
	title,
	options,
	noDataAvailableLabel,
	selectPrompt,
	icon,
	selectedItem,
	checkable,
	searchable,
	itemChecked,
	itemDisabled = () => {
		return false;
	},
	itemOnChange,
	itemDisplayerData,
	itemDisplayerOnClick,
	useIndividualInteractions,
	useCategories,
	useIndividualItemDisplays,
	dropup,
	className,
	columnedCategories,
	horizontalCategories,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [searchDetails, setSearchDetails] = useState();

	return (
		<div
			className={
				className ||
				css`
					display: flex;
					margin: 1vh;
					width: 100%;
					position: relative;
					min-height: 5vh;
					margin-left: 15px;
					font-size: 89%;
					flex-flow: row wrap;
					align-content: center;
					align-items: center;
					&:hover {
						background-color: var(--sidebar-dropdown-bg-color);
					}
				`
			}
			onMouseEnter={() => {
				setDropdownOpen(true);
			}}
			onMouseLeave={() => {
				setDropdownOpen(false);
			}}
		>
			<img src={icon} className="icon" alt="" />
			<span>{title}</span>
			<img
				src={arrow_icon}
				className={css`
					position: absolute;
					width: 12px;
					height: 12px;
					filter: invert(100%);
					right: 12px;
					transform: rotate(90deg);
				`}
				alt=""
			/>
			{/* if there are options to display then dont cap the height, but if there isnt items to dispaly, set the height to 100% */}
			<div
				className={
					useMediaQuery({ query: "(max-width: 700px)" })
						? css`
								display: ${dropdownOpen ? "block" : "none"};
								/* flex:1 1 1; */
								position: absolute;
								background-color: var(--sidebar-dropdown-bg-color);
								text-align: left;
								z-index: 1;
								transform: translateY(99%);
								bottom: 0;
								box-shadow: 0px 0px 5px 1px rgb(27, 27, 27);
						  `
						: css`
								display: ${dropdownOpen ? "block" : "none"};
								/* flex:1 1 1; */
								position: absolute;
								background-color: var(--sidebar-dropdown-bg-color);
								text-align: left;
								z-index: 1;
								transform: translateX(99%);
								right: 0;
								top: 0;
								box-shadow: 0px 0px 5px 1px rgb(27, 27, 27);
						  `
				}
				style={
					options.length
						? dropup
							? { top: "auto", bottom: "0" }
							: null
						: { height: "100%" }
				}
			>
				<div
					className={css`
						padding: 0 10px 10px 10px;
						width: max-content;
						max-height: 40vh;
						overflow-y: auto;
						// transform: scale(1);
					`}
				>
					{options.length ? (
						<div>
							<span>{selectPrompt}</span>
							<hr style={{ marginTop: "0px" }} />
						</div>
					) : (
						<span>{noDataAvailableLabel}</span>
					)}
					{searchable && (
						<input
							className="Searchbar"
							placeholder="Search..."
							style={!options.length ? { display: "none" } : null}
							onChange={(event) => {
								setSearchDetails(
									event.target.value.replaceAll(":", "").toLowerCase()
								);
							}}
						></input>
					)}
					{dropdownOpen && options.length ? (
						!options.includes("Invalid API key") ? (
							!useCategories ? (
								options
									.filter(
										(item) =>
											!searchDetails ||
											item.label
												.replaceAll(":", "")
												.toLowerCase()
												.includes(searchDetails)
									)
									.map((item, i) => {
										return (
											<Item
												key={i}
												data={item}
												checkable={checkable}
												selectedItem={selectedItem}
												checked={
													useIndividualInteractions ? item.checked : itemChecked
												}
												disabled={
													useIndividualInteractions
														? item.disabled
														: itemDisabled
												}
												onChange={
													useIndividualInteractions
														? item.onChange
														: itemOnChange
												}
											></Item>
										);
									})
							) : (
								<div
									className={
										columnedCategories
											? css`
													display: flex;
													column-width: 140px;
													column-gap: 20px;
													overflow: auto;
											  `
											: ""
									}
								>
									{options.map((category, i) => {
										return (
											<Category
												key={i}
												data={category}
												checkable={checkable}
												selectedItem={selectedItem}
												itemChecked={itemChecked}
												itemDisabled={itemDisabled}
												itemOnChange={itemOnChange}
												useIndividualInteractions={useIndividualInteractions}
												columnedCategories={columnedCategories}
												horizontalCategories={horizontalCategories}
											></Category>
										);
									})}
								</div>
							)
						) : (
							<span></span>
						)
					) : null}
				</div>
			</div>

			<SelectionDisplayer
				data={itemDisplayerData}
				onClick={itemDisplayerOnClick}
				useIndividualItemDisplays={useIndividualItemDisplays}
			></SelectionDisplayer>
		</div>
	);
};

export default Dropdown;
