import { useState } from "react";
import arrow_icon from "../assets/images/arrow_icon.png";
import Item from "./Item";

const SelectableTable = ({
	children,
	title,
	options,
	noDataAvailableLabel,
	searchable,
	selectedItem,
	itemOnCick,
}) => {
	const [collapsed, setCollapsed] = useState();
	const [searchDetails, setSearchDetails] = useState();
	return (
		<div className="SelectableTable">
			<div className="TableTitle">
				<span>{title}</span>

				{searchable && (
					<input
						className="Searchbar"
						placeholder="Search..."
						style={{ width: "200px", height: "25px" }}
						onChange={(event) => {
							setSearchDetails(event.target.value.toLowerCase());
						}}
					></input>
				)}
				{children}
				<img
					src={arrow_icon}
					className="collapseArrow"
					collapsed={collapsed ? "true" : null}
					alt=""
					onClick={() => {
						setCollapsed(!collapsed);
					}}
				/>
			</div>
			<div className="DataHolder" style={collapsed ? { display: "none" } : {}}>
				{options
					.filter(
						(item) =>
							!searchDetails || item.label.toLowerCase().includes(searchDetails)
					)
					.map((data, i) => {
						return (
							<Item
								key={i}
								data={data}
								masterData={options}
								selectedItem={selectedItem}
								onClick={itemOnCick}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default SelectableTable;
