// import React, { useEffect, useState } from 'react'

import { useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import arrow_icon from "./assets/images/arrow_icon.png";

const APConfigTable = ({ apConfigData, collapsed, setCollapsed }) => {
	return (
		<div className="APConfigTable">
			<div className="TableTitle">
				<span>Access Points</span>
				<img
					src={arrow_icon}
					className="collapseArrow"
					collapsed={collapsed ? "true" : null}
					alt=""
					onClick={() => {
						setCollapsed(!collapsed);
					}}
				/>
			</div>
			<Table
				size="sm"
				striped
				hover
				style={collapsed ? { display: "none" } : {}}
			>
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Status</th>
						<th scope="col">AP MAC</th>
						<th scope="col">Model</th>
						<th scope="col">Switch / Port</th>
						<th scope="col">Firmware</th>
						<th scope="col">Channel</th>
						<th scope="col">Channel Width</th>
						<th scope="col">Tx Power</th>
						<th scope="col" title="Last 5 minutes">
							Channel Utilization
						</th>
						<th scope="col" title="Last 5 minutes">
							Client Count
						</th>
						<th scope="col" title="Last 24 hours">
							Connectivity Issues
						</th>
					</tr>
				</thead>
				<tbody>
					{apConfigData.errors !== "Invalid API key"
						? apConfigData.map((config, i) => {
								// console.log(config);
								// console.log(`switch port: ${config.switchAndPort}`);
								return (
									<tr key={i}>
										{/* Retreve and parse the date and time */}
										<td>{config.deviceName}</td>
										<td>{config.deviceStatus}</td>
										<td>{config.deviceMac}</td>
										<td>{config.deviceModel}</td>
										<td>
											{config.switchAndPort &&
												config.switchAndPort.switchName +
													" / " +
													config.switchAndPort.portNumber.toString()}
										</td>
										<td>
											{config.deviceFirmware !==
											"Not running configured version"
												? config.deviceFirmware
												: "custom"}
										</td>
										<td>{config.channels}</td>
										<td>{config.channelWidths}</td>
										<td>{config.power}</td>
										<td>{config.utilization}</td>
										<td>{config.deviceClientCount}</td>
										<td
											title={Object.entries(
												config.deviceConnectivityIssues
											).join("\n")}
										>
											{Object.values(config.deviceConnectivityIssues).reduce(
												(a, b) => a + b,
												0
											) - config.deviceConnectivityIssues.success}
										</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</Table>
		</div>
	);
};

// deviceStats.assoc +
// deviceStats.auth +
// deviceStats.dhcp +
// deviceStats.dns;
export default APConfigTable;
