import { useEffect, useState } from "react";
// import arrow_icon from "./assets/images/arrow_icon.png";
const SelectionDisplayer = ({ data, onClick, useIndividualItemDisplays }) => {
	if (data) {
		return (
			<div className="selectedFilterDisplay">
				{data.map((item, i) => {
					return (
						<div key={i} className="filterChoice">
							<span>{item.label}</span>
							{(onClick || useIndividualItemDisplays) && (
								<span
									className="deselectButton"
									onClick={() => {
										if (useIndividualItemDisplays) item.onClick(item);
										else onClick(item);
									}}
								>
									x
								</span>
							)}
						</div>
					);
				})}
			</div>
		);
	}
};

export default SelectionDisplayer;
