// import React, { useEffect, useState } from 'react'

import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { css } from "@emotion/css";
import app_logo from "./assets/images/app_logo.png";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import online_icon from "./assets/images/tpg/online.png";
import offline_icon from "./assets/images/tpg/offline.png";
import alert_icon from "./assets/images/tpg/alert.png";
import dormant_icon from "./assets/images/tpg/dormant.png";
import mesh_icon from "./assets/images/tpg/mesh.png";
import online_on_map_icon from "./assets/images/tpg/online-map.png";

// import FlexibleDropdown from "./FlexibleDropdown";
const TPGStatusSidebar = () => {
	return (
		<div className="SideBar">
			<a className="logoMain" href="https://merakiwifi.app/">
				<img
					src={app_logo}
					alt=""
					className={css`
						width: 200px;
					`}
				/>
			</a>
			<div
				className={css`
					display: flex;
					width: 100%;
					justify-content: space-around;
					margin-top: 12px;
					margin-bottom: 12px;
				`}
			>
				<Link to="/tpg" className="NavigationButton">
					TPG Status Dashboard
				</Link>
			</div>
			<ul className="TPGStatusKey">
				<li
					className={css`
						text-align: center;
						font-weight: bold;
					`}
				>
					<span>Icon Key:</span>
				</li>
				<li>
					<img src={online_icon} />
					<span>Online</span>
				</li>
				<li>
					<img src={offline_icon} />
					<span>Offline</span>
				</li>
				<li>
					<img src={alert_icon} />
					<span>Alert</span>
				</li>
				<li>
					<img src={dormant_icon} />
					<span>Dormant</span>
				</li>
				<li>
					<img src={mesh_icon} />
					<span>Mesh AP</span>
				</li>
				<li>
					<img src={online_on_map_icon} />
					<span>On map</span>
				</li>
			</ul>
			{/* <div className="TPGStatusKey">
				<Table>
					<thead>
						<tr>
							<th>Online</th>
							<th>Ofline</th>
							<th>Alert</th>
							<th>Dormant</th>
							<th>On map</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<img src={online_icon} />
							</td>
							<td>
								<img src={offline_icon} />
							</td>
							<td>
								<img src={alert_icon} />
							</td>
							<td>
								<img src={dormant_icon} />
							</td>
							<td>
								<img src={online_on_map_icon} />
							</td>
						</tr>
					</tbody>
				</Table>
			</div> */}
			{/* <p
				className={css`
					padding: 8px;
				`}
			>
				<img src={online_icon} /> 
			</p> */}
		</div>
	);
};

export default TPGStatusSidebar;
