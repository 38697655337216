import { useState } from "react";
import Popup from "./Popup";

const Disclaimer = ({ label, title, popupLabel, className }) => {
	const [poppedUp, setPoppedUp] = useState(false);
	return (
		<div className={className || "disclaimer"}>
			<span
				onClick={() => {
					setPoppedUp(!poppedUp);
				}}
			>
				{label}
			</span>
			<Popup title={title} label={popupLabel} poppedUp={poppedUp} setPoppedUp={setPoppedUp}></Popup>
		</div>
	);
};

export default Disclaimer;
