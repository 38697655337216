// import React, { useEffect, useState } from 'react'

import { useEffect, useState } from "react";
import arrow_icon from "./assets/images/arrow_icon.png";
import app_logo from "./assets/images/app_logo.png";
import info_icon from "./assets/images/info_icon.png";
import channel_width_icon from "./assets/images/channel_width_icon.png";
import channels_icon from "./assets/images/channels_icon.png";
import client_balancing_icon from "./assets/images/client_balancing_icon.png";
import min_bitrate_icon from "./assets/images/min_bitrate_icon.png";
import power_icon from "./assets/images/power_icon.png";
import networks_icon from "./assets/images/networks_icon.png";
import Dropdown from "./Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import ToggleSwitch from "./ToggleSwitch";
// import FlexibleDropdown from "./FlexibleDropdown";
const ConfigAuditSideBar = ({
	bestPracticeConfig,
	setBestPracticeConfig,
	orgIDs,
	selectedOrgID,
	setSelectedOrgID,
}) => {
	useEffect(() => {
		if (typeof orgIDs !== Array && orgIDs.length === 1) {
			console.log(orgIDs);
			setSelectedOrgID(orgIDs[0].id);
		}
	}, [orgIDs]);
	return (
		<div className="SideBar">
			<a className="logoMain" href="https://merakiwifi.app/">
				<img src={app_logo} alt="" />
			</a>
			<div
				className={css`
					display: flex;
					width: 100%;
					justify-content: space-around;
					margin-top: 12px;
					margin-bottom: 12px;
				`}
			>
				<Link to="/" className="NavigationButton">
					Debug Dashboard
				</Link>
				<Link to="/WifiConfigAudit" className="NavigationButton">
					Config Audit
				</Link>
			</div>
			<hr
				className={css`
					width: 100%;
					margin-top: 0px;
				`}
			></hr>
			<div className="Title">
				<span>Wi-Fi Config Audit Settings</span>
				<img
					src={info_icon}
					alt=""
					className="moreInfoIcon"
					data-toggle="tooltip"
					title="The following Wi-Fi config settings are considered best practice, and are the rules used to determine if your configs are within alignment.  Because not all Wi-Fi environments are the same, you can edit the rules as you see fit and then rerun the audit."
				/>
			</div>

			<hr
				className={css`
					width: 100%;
					margin: 0px;
				`}
			></hr>
			{orgIDs.length > 1 && (
				<Dropdown
					title="Organizations:"
					options={orgIDs.map((orgID) => {
						return {
							label: orgID.name,
							details: orgID,
						};
					})}
					searchable={orgIDs.length > 10}
					icon={networks_icon}
					noDataAvailableLabel="No Organizations Available"
					selectPrompt="Select Organization ID:"
					selectedItem={(item) => {
						return item.details.id === selectedOrgID;
					}}
					itemOnChange={(item) => {
						setSelectedOrgID(item.details.id);
					}}
					itemDisplayerData={
						selectedOrgID && [
							{
								label: orgIDs.filter((orgID) => orgID.id === selectedOrgID)[0]
									.name,
							},
						]
					}
				/>
			)}
			<Dropdown
				title="Channels:"
				options={[
					{
						label: "UNII-1",
						items: [
							{ label: 36, details: { category: "UNII-1" } },
							{ label: 40, details: { category: "UNII-1" } },
							{ label: 44, details: { category: "UNII-1" } },
							{ label: 48, details: { category: "UNII-1" } },
						],
					},
					{
						label: "UNII-2",
						items: [
							{ label: 52, details: { category: "UNII-2" } },
							{ label: 56, details: { category: "UNII-2" } },
							{ label: 60, details: { category: "UNII-2" } },
							{ label: 64, details: { category: "UNII-2" } },
						],
					},
					{
						label: "UNII-2-Extended",
						items: [
							{ label: 100, details: { category: "UNII-2-Extended" } },
							{ label: 104, details: { category: "UNII-2-Extended" } },
							{ label: 108, details: { category: "UNII-2-Extended" } },
							{ label: 112, details: { category: "UNII-2-Extended" } },
							{ label: 116, details: { category: "UNII-2-Extended" } },
							{ label: 120, details: { category: "UNII-2-Extended" } },
							{ label: 124, details: { category: "UNII-2-Extended" } },
							{ label: 128, details: { category: "UNII-2-Extended" } },
							{ label: 132, details: { category: "UNII-2-Extended" } },
							{ label: 136, details: { category: "UNII-2-Extended" } },
							{ label: 140, details: { category: "UNII-2-Extended" } },
							{ label: 144, details: { category: "UNII-2-Extended" } },
						],
					},
					{
						label: "UNII-3",
						items: [
							{ label: 149, details: { category: "UNII-3" } },
							{ label: 153, details: { category: "UNII-3" } },
							{ label: 157, details: { category: "UNII-3" } },
							{ label: 161, details: { category: "UNII-3" } },
							{ label: 165, details: { category: "UNII-3" } },
						],
					},
				]}
				icon={channels_icon}
				selectPrompt="Select Custom 5GHz Channels"
				useCategories
				columnedCategories
				checkable
				itemChecked={(item) =>
					bestPracticeConfig.channels[item.details.category].includes(
						item.label
					)
				}
				itemOnChange={(item) => {
					if (
						bestPracticeConfig.channels[item.details.category].includes(
							item.label
						)
					) {
						bestPracticeConfig.channels[item.details.category] =
							bestPracticeConfig.channels[item.details.category].filter(
								(lookup) => lookup !== item.label
							);
					} else {
						bestPracticeConfig.channels[item.details.category].push(item.label);
					}
					setBestPracticeConfig({ ...bestPracticeConfig });
				}}
			/>
			<Dropdown
				title="Channel Width:"
				options={[
					{
						label: "2.4 GHz",
						items: [{ label: "20MHz", details: { category: "2.4 GHz" } }],
					},
					{
						label: "5 GHz",
						items: [
							{ label: "20MHz", details: { category: "5 GHz" } },
							{ label: "40MHz", details: { category: "5 GHz" } },
							{ label: "80MHz", details: { category: "5 GHz" } },
						],
					},
					{
						label: "6 GHz",
						items: [
							{ label: "20MHz", details: { category: "6 GHz" } },
							{ label: "40MHz", details: { category: "6 GHz" } },
							{ label: "80MHz", details: { category: "6 GHz" } },
							{ label: "160MHz", details: { category: "6 GHz" } },
						],
					},
				]}
				icon={channel_width_icon}
				selectPrompt="Select Custom Channel Width"
				useCategories
				selectedItem={(item) => {
					return (
						item.label ===
						bestPracticeConfig.channelWidths[item.details.category]
					);
				}}
				itemOnChange={(item) => {
					bestPracticeConfig.channelWidths[item.details.category] = item.label;
					setBestPracticeConfig({ ...bestPracticeConfig });
				}}
			/>
			<Dropdown
				title="Tx Power:"
				selectPrompt="Type Custom Tx Power"
				options={[
					{
						label: "2.4GHz Power:",
						items: [
							{
								label: "min:",
								defaultValue: bestPracticeConfig.minPowers["2.4 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.minPowers["2.4 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
							{
								label: "max:",
								defaultValue: bestPracticeConfig.maxPowers["2.4 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.maxPowers["2.4 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
						],
					},

					{
						label: "5GHz Power:",
						noInteraction: true,
						items: [
							{
								label: "min:",
								defaultValue: bestPracticeConfig.minPowers["5 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.minPowers["5 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
							{
								label: "max:",
								defaultValue: bestPracticeConfig.maxPowers["5 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.maxPowers["5 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
						],
					},

					{
						label: "6GHz Power:",
						noInteraction: true,
						items: [
							{
								label: "min:",
								defaultValue: bestPracticeConfig.minPowers["6 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.minPowers["6 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
							{
								label: "max:",
								defaultValue: bestPracticeConfig.maxPowers["6 GHz"],
								isInput: true,
								onChange: (e) => {
									bestPracticeConfig.maxPowers["6 GHz"] = e.target.value;
									setBestPracticeConfig({ ...bestPracticeConfig });
								},
							},
						],
					},
				]}
				icon={power_icon}
				useCategories
				useIndividualInteractions
				horizontalCategories
			/>
			<Dropdown
				title="Min. Bitrate:"
				options={[
					{
						label: "2.4 GHz",
						items: [
							{ label: "1", details: { category: "2.4 GHz" } },
							{ label: "2", details: { category: "2.4 GHz" } },
							{ label: "5.5", details: { category: "2.4 GHz" } },
							{ label: "6", details: { category: "2.4 GHz" } },
							{ label: "9", details: { category: "2.4 GHz" } },
							{ label: "11", details: { category: "2.4 GHz" } },
							{ label: "12", details: { category: "2.4 GHz" } },
							{ label: "18", details: { category: "2.4 GHz" } },
							{ label: "24", details: { category: "2.4 GHz" } },
							{ label: "36", details: { category: "2.4 GHz" } },
							{ label: "48", details: { category: "2.4 GHz" } },
							{ label: "54", details: { category: "2.4 GHz" } },
						],
					},
					{
						label: "5 GHz",
						items: [
							{ label: "1", details: { category: "5 GHz" } },
							{ label: "2", details: { category: "5 GHz" } },
							{ label: "5.5", details: { category: "5 GHz" } },
							{ label: "6", details: { category: "5 GHz" } },
							{ label: "9", details: { category: "5 GHz" } },
							{ label: "11", details: { category: "5 GHz" } },
							{ label: "12", details: { category: "5 GHz" } },
							{ label: "18", details: { category: "5 GHz" } },
							{ label: "24", details: { category: "5 GHz" } },
							{ label: "36", details: { category: "5 GHz" } },
							{ label: "48", details: { category: "5 GHz" } },
							{ label: "54", details: { category: "5 GHz" } },
						],
					},
					{
						label: "6 GHz",
						items: [
							{ label: "1", details: { category: "6 GHz" } },
							{ label: "2", details: { category: "6 GHz" } },
							{ label: "5.5", details: { category: "6 GHz" } },
							{ label: "6", details: { category: "6 GHz" } },
							{ label: "9", details: { category: "6 GHz" } },
							{ label: "11", details: { category: "6 GHz" } },
							{ label: "12", details: { category: "6 GHz" } },
							{ label: "18", details: { category: "6 GHz" } },
							{ label: "24", details: { category: "6 GHz" } },
							{ label: "36", details: { category: "6 GHz" } },
							{ label: "48", details: { category: "6 GHz" } },
							{ label: "54", details: { category: "6 GHz" } },
						],
					},
				]}
				icon={min_bitrate_icon}
				selectPrompt="Select Custom Min. Bitrate"
				columnedCategories
				useCategories
				dropup
				selectedItem={(item) => {
					return (
						item.label === bestPracticeConfig.minBitrate[item.details.category]
					);
				}}
				itemOnChange={(item) => {
					bestPracticeConfig.minBitrate[item.details.category] = item.label;
					setBestPracticeConfig({ ...bestPracticeConfig });
				}}
			/>
			<Dropdown
				title="Client Balancing:"
				options={[
					{
						customComponent: (
							<div>
								<ToggleSwitch
									checked={bestPracticeConfig.clientBalancingEnabled}
									onClick={() => {
										bestPracticeConfig.clientBalancingEnabled =
											!bestPracticeConfig.clientBalancingEnabled;
										setBestPracticeConfig({ ...bestPracticeConfig });
									}}
								/>
								<span
									className={css`
										margin-left: 6px;
									`}
								>
									{bestPracticeConfig.clientBalancingEnabled
										? "Enabled"
										: "Disabled"}
								</span>
							</div>
						),
					},
				]}
				icon={client_balancing_icon}
				selectPrompt="Select Custom Client Balancing Value"
				// checkable
				// useIndividualInteractions
			/>
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "center",
					marginTop: "24px",
				}}
			>
				<button
					className="button"
					onClick={() => {
						setBestPracticeConfig({
							channels: {
								"UNII-1": [36, 40, 44, 48],
								"UNII-2": [52, 56, 60, 64],
								"UNII-2-Extended": [
									100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144,
								],
								"UNII-3": [149, 153, 157, 161, 165],
							},
							channelWidths: {
								"2.4 GHz": "20MHz",
								"5 GHz": "20MHz",
								"6 GHz": "20MHz",
							},
							minPowers: { "2.4 GHz": "5", "5 GHz": "14", "6 GHz": "14" },
							maxPowers: { "2.4 GHz": "8", "5 GHz": "17", "6 GHz": "17" },
							minBitrate: { "2.4 GHz": "11", "5 GHz": "12", "6 GHz": "12" },
							clientBalancingEnabled: false,
						});
					}}
				>
					Reset Config
				</button>
			</div>
		</div>
	);
};

export default ConfigAuditSideBar;
