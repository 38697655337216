import React, { useEffect, useState } from "react";
// import logo from './logo.svg';
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WifiDebugDashboard from "./pages/WifiDebugDashboard";
import WifiConfigAudit from "./pages/WifiConfigAudit";
import TPGStatusDashboard from "./pages/TPGStatusDashboard";
import BSSIDDashboard from "./pages/BSSIDDashboard";
const router = createBrowserRouter([
	{
		path: "/",
		element: <WifiDebugDashboard />,
	},
	{
		path: "/WifiConfigAudit",
		element: <WifiConfigAudit />,
	},
	{
		path: "/TPG",
		element: <TPGStatusDashboard />,
	},
	{
		path: "/BSSID",
		element: <BSSIDDashboard />,
	},
]);

const REQUEST_BIN_URL = "https://enk95mcowe2m.x.pipedream.net";
function App() {
	return (
		<div className="App">
			{/* <head>
				
			</head> */}
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
