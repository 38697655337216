// import React, { useEffect, useState } from 'react'

import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { css } from "@emotion/css";
import app_logo from "./assets/images/app_logo.png";
import { Link } from "react-router-dom";
import networks_icon from "./assets/images/networks_icon.png";
import ssid_icon from "./assets/images/ssid_icon.png";

// import FlexibleDropdown from "./FlexibleDropdown";
const BSSIDSidbar = ({
	orgIDs,
	selectedOrgID,
	setSelectedOrgID,
	networkIDs,
	selectedNetworkID,
	setSelectedNetworkID,
	networkSSIDs,
	selectedSSIDs,
	setSelectedSSIDs,
	sidebarOpen,
}) => {
	// console.log(networkSSIDs);
	// useEffect(() => {
	// 	if (typeof orgIDs !== Array && orgIDs.length === 1) {
	// 		console.log(orgIDs);
	// 		setSelectedOrgID(orgIDs[0].id);
	// 	}
	// }, [orgIDs]);
	return (
		<div className={`SideBar ${sidebarOpen && "open"}`}>
			<a className="logoMain" href="https://merakiwifi.app/">
				<img
					src={app_logo}
					alt=""
					className={css`
						width: 200px;
					`}
				/>
			</a>
			<div
				className={css`
					display: flex;
					width: 100%;
					justify-content: space-around;
					margin-top: 12px;
					margin-bottom: 12px;
				`}
			>
				<Link to="/bssid" className="NavigationButton">
					BSSID Dashboard
				</Link>
			</div>
			{orgIDs.length > 1 && (
				<Dropdown
					title="Organizations:"
					options={orgIDs.map((orgID) => {
						return {
							label: orgID.name,
							details: orgID,
						};
					})}
					searchable={orgIDs.length > 10}
					icon={networks_icon}
					noDataAvailableLabel="No Organizations Available"
					selectPrompt="Select Organization ID:"
					selectedItem={(item) => {
						return item.details.id === selectedOrgID;
					}}
					itemOnChange={(item) => {
						setSelectedOrgID(item.details.id);
					}}
					itemDisplayerData={
						selectedOrgID && [
							{
								label: orgIDs.filter((orgID) => orgID.id === selectedOrgID)[0]
									.name,
							},
						]
					}
				/>
			)}
			<Dropdown
				title="Network:"
				options={networkIDs.map((networkID) => {
					return {
						label: networkID.name,
						details: networkID,
					};
				})}
				// searchable={networkIDs.length > 10}
				icon={networks_icon}
				noDataAvailableLabel="No Networks Available"
				selectPrompt="Select Network ID:"
				selectedItem={(item) => {
					return item.details.id === selectedNetworkID.id;
				}}
				itemOnChange={async (item) => {
					setSelectedNetworkID(item.details);
					// setNetworkIDName(item.label);
					// setNetworkClients(
					// 	await fetchNetworkClients(apiKey, item.details.id)
					// );
					// setDevices(await fetchNetworkDevices(apiKey, item.details.id));
				}}
				// itemDisplayerData={selectedNetworkID}
			/>
			<Dropdown
				title={"SSIDs:"}
				options={networkSSIDs.map((ssid) => {
					let [ssidNumber, ssidConfig] = Object.entries(ssid)[0];
					return {
						label: ssidConfig[0].ssidName,
						data: ssidConfig[0],
					};
				})}
				noDataAvailableLabel="No SSIDs Available"
				selectPrompt={"Select Network SSID(s):"}
				icon={ssid_icon}
				checkable
				itemChecked={(item) => {
					// console.log(item);
					return selectedSSIDs.includes(item.data.ssidNumber);
				}}
				itemOnChange={(item) => {
					if (selectedSSIDs.includes(item.data.ssidNumber)) {
						setSelectedSSIDs((current) =>
							current.filter((ssid) => ssid !== item.data.ssidNumber)
						);
					} else {
						setSelectedSSIDs((current) => [...current, item.data.ssidNumber]);
					}
				}}
				// itemDisplayerData={selectedCustomFilters
				// 	.filter((filter) => filter.split(":")[0] === "ssidName")
				// 	.map((el) => {
				// 		return {
				// 			label: el.split(":")[1],
				// 		};
				// 	})}
				// itemDisplayerOnClick={(item) => {
				// 	setSelectedCustomFilters(
				// 		selectedCustomFilters.filter(
				// 			(data, index) => data !== "ssidName:" + item.label
				// 		)
				// 	);
				// }}
			/>
		</div>
	);
};

export default BSSIDSidbar;
