import { css } from "@emotion/css";
import Item from "./Item";
const Category = ({
	data,
	checkable,
	selectedItem,
	itemChecked,
	itemDisabled,
	itemOnChange,
	useIndividualInteractions,
	horizontalCategories,
	columnedCategories,
}) => {
	return (
		<div>
			<span>{data.label}</span>
			<div
				className={
					horizontalCategories
						? css`
								display: flex;
						  `
						: ""
				}
			>
				{data.items.map((item, i) => {
					// if (!item.checked) console.log(data);
					return (
						<div key={i}>
							<Item
								style={
									!columnedCategories
										? { marginLeft: "24px" }
										: { minWidth: "50px" }
								}
								data={item}
								checkable={checkable}
								selectedItem={selectedItem}
								checked={useIndividualInteractions ? item.checked : itemChecked}
								disabled={
									useIndividualInteractions ? item.disabled : itemDisabled
								}
								onChange={
									useIndividualInteractions ? item.onChange : itemOnChange
								}
							></Item>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Category;
