import { css } from "@emotion/css";
import { useState } from "react";

const ToggleSwitch = ({ checked, onClick }) => {
	const [toggled, setToggled] = useState(false);
	return (
		<div
			className={css`
				position: relative;
				display: inline-block;
				width: 30px;
				height: 17px;
			`}
		>
			<input
				className={css`
					opacity: 0;
					width: 0;
					height: 0;
				`}
				type="checkbox"
				defaultChecked={checked}
			/>
			<span
				className={css`
					position: absolute;
					cursor: pointer;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: #ccc;
					transition: 0.4s;
					border-radius: 34px;
					&:before {
						${checked
							? `
						transform: translateX(13px);
						`
							: ""}
						border-radius: 50%;
						position: absolute;
						content: "";
						height: 13px;
						width: 13px;
						left: 2px;
						bottom: 2px;
						background-color: white;
						transition: 0.4s;
					}
					${checked
						? `
					background-color: #64b348;
					`
						: ""}
				`}
				onClick={onClick}
			></span>
		</div>
	);
};

export default ToggleSwitch;
