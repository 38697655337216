// import React, { useEffect, useState } from 'react'

import { useEffect, useState } from "react";
import Disclaimer from "./Disclaimer";
import { useMediaQuery } from "react-responsive";

const TopBar = ({
	fetchOrgIDs,
	setOrgIDs,
	fetchAPIKeyHolder,
	apiKeyHolder,
	setApiKeyHolder,
	apiKey,
	setApiKey,
	csvData,
}) => {
	return (
		<div className="TopBar">
			<div>
				<input
					className="APIKeyInput"
					placeholder="API Key:"
					onChange={async (event) => {
						await setApiKey(event.target.value);
						await fetchAPIKeyHolder(event.target.value, setApiKeyHolder);
						if (setOrgIDs) {
							setOrgIDs(await fetchOrgIDs(event.target.value));
						}
						// Triggers fetching Network IDs
					}}
				></input>
				{/* <img className="AcceptedKeyIcon" src={(validAPIKey ? green_check : red_check)}></img> */}
			</div>
			<div>
				{apiKeyHolder && apiKey ? (
					apiKeyHolder.name ? (
						<span>Welcome {apiKeyHolder.name}</span>
					) : (
						<span>Invalid API Key</span>
					)
				) : (
					<span>
						Enter Your API Key <span style={{ color: "red" }}>*</span>
					</span>
				)}
			</div>
			{!useMediaQuery({ query: "(max-width: 700px)" }) && (
				<Disclaimer
					label={
						<span>
							This site does not save, store, or archive API keys. See our{" "}
							<a href="#" style={{ color: "white" }}>
								Terms of Use
							</a>{" "}
							for more details.
						</span>
					}
					title="Terms of Use:"
					popupLabel={
						<ul>
							<li>
								<span>
									<strong>Privacy Policy:</strong> No information is stored
									outside of the running instance of this Site. Although
									information is not stored, we do not guarantee that Internet
									transmissions will always be completely private or secure.
									This Site relies upon the Cisco Meraki Dashboard API for
									retrieving and updating a Meraki Network. Access granted to
									the Meraki network is governed by the API key created by the
									administrator of the given network.
								</span>
							</li>
							<li>
								<span>
									<strong>Terms of Use:</strong> The following terms and
									conditions (“Terms of Use”) govern your use of this Site. BY
									USING THE SITE IN ANY WAY, YOU ACKNOWLEDGE THAT YOU HAVE READ,
									UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU
									DO NOT AGREE, DO NOT USE THE SITE. This Site is free for
									public use with no guarantees or official support. We reserve
									the right, in our sole discretion and without any liability,
									to make changes to the Site and any Site Content, wholly or
									partly, at any time without prior notice. We make no
									commitment to update the Site regularly and therefore some
									Site Content may be out of date.
								</span>
							</li>
							<li>
								<span>
									<strong>Usage Restrictions:</strong>You must comply with all
									applicable laws when using the Site. Except as may be
									expressly permitted by applicable law or expressly permitted
									by us in writing, you will not, and will not permit anyone
									else to: (a) store, copy, modify, distribute, or resell any
									Site Content or compile or collect any Site Content as part of
									a database or other work; (b) use any automated tool to use
									the Site or store, copy, modify, distribute, or resell any
									Site Content; (c) use the Site and Site Content for any
									purpose except as agreed to in these Terms of Use or upload
									any User Content in any manner that may infringe any
									intellectual property right, proprietary right, or property
									right of us or any third parties; (d) circumvent or disable
									any digital rights management, usage rules, or other security
									features of the Site; (e) reproduce, modify, translate,
									enhance, decompile, disassemble, reverse engineer, or create
									derivative works of the Site or Site Content; (f) use the Site
									in a manner that threatens the integrity, performance, or
									availability of the Site; or (g) remove, alter, or obscure any
									portion of the Site or Site Content.
								</span>
							</li>
							<li>
								<span>
									<strong>Disclaimer of Warranties:</strong> YOUR USE OF THE
									SITE, THE SITE CONTENT, INCLUDING ANY SUBMISSION BY YOU OR
									ANYONE USING YOUR API KEY(S), AND RESULTS YOU RECEIVE FROM THE
									SITE, IS AT YOUR SOLE DISCRETION AND RISK. THE SITE AND SITE
									CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
									WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS
									OF THE SITE OR SITE CONTENT, OR THAT THE SITE WILL BE
									UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, SECURE, TIMELY OR THAT
									ANY DEFECT WILL BE CORRECTED. YOU WILL BE SOLELY RESPONSIBLE
									FOR ANY DAMAGE TO YOUR COMPUTER, COMUTPER NETWORK, OR LOSS OF
									DATA THAT RESULTS FROM USE OF THE SITE. YOUR SOLE REMEDY FOR
									ANY DISSATISFACTION WITH THE SITE OR ANY SITE CONTENT IS TO
									CEASE USING THE SITE OR THE SITE CONTENT.
								</span>
							</li>
							<li>
								<span>
									<strong>Limitation of Liability:</strong> IN NO EVENT SHALL WE
									BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
									INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR EXEMPLARY
									DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
									PROFITS OR REVENUES, BUSINESS INTERRUPTION, GOODWILL, USE,
									DATA OR OTHER INTANGIBLE LOSSES ARISING FROM YOUR USE AND
									ACCESS OF THE SITE AND SITE CONTENT, OR DAMAGES RELATED TO
									YOUR RELIANCE ON ANY INFORMATION OBTAINED FROM THE SITE. THIS
									SECTION APPLIES WHETHER DAMAGES ARE BASED ON WARRANTY,
									CONTRACT, TORT, STATUTE, OR ANY OTHER LEGAL THEORY.
								</span>
							</li>
						</ul>
					}
					className="APIKeyDisclaimer"
				></Disclaimer>
			)}
			{/* <span className="APIKeyDisclaimer">
				This site does not save, store, or archive API keys. See our{" "}
				<a style={{ color: "white" }} href="https://wifi-fu.com/terms-of-use/">
					Terms of Use
				</a>{" "}
				for more details.
			</span> */}
		</div>
	);
};

export default TopBar;
