import React, { Profiler, useEffect, useState } from "react";
import "../App.css";
import ConfigAuditSideBar from "../ConfigAuditSideBar.js";
import WifiConfigTable from "../WifiConfigTable";
import TopBar from "../TopBar.js";
import SelectableTable from "../SelectableTable";
import Disclaimer from "../Disclaimer";
import kofi_tip_icon from "../assets/images/kofi_tip_icon.png";
import venmo_tip_icon from "../assets/images/venmo_tip_icon.png";
import linkedin_icon from "../assets/images/linkedin_icon.png";
// import Table from "../Table";
import Button from "../Button";
import { css } from "@emotion/css";

const REQUEST_BIN_URL = "https://enk95mcowe2m.x.pipedream.net";

const fetchAPIKeyHolder = async (apiKey, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchAPIKeyHolder`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch API Key Holder Errors: ", data.errors[0]);
			}
			setter(data);
		});
};

const fetchOrgIDs = async (apiKey, setter) => {
	return await fetch(`https://merakiwifi.app/api/FetchOrgIDs`, {
		headers: {
			apikey: apiKey,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Orginazation IDs Errors: ", data.errors[0]);
				// setter();
				return [data.errors[0]];
			}
			return data;
			// return data
		});
};

const fetchNetworkIDs = async (apiKey, orgID, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchNetworkIDs`, {
		headers: {
			apikey: apiKey,
			organizationid: orgID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network IDs Errors: ", data.errors[0]);
				setter([data.errors[0]]);
				return;
			}
			setter(data);
		});
};

const fetchNetworkDevices = async (apiKey, networkID, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchNetworkDevices`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Devices Errors: ", data.errors[0]);
			}
			// console.log({ networkID: data });
			setter((current) => ({ ...current, [networkID]: data }));
		});
};

const fetchOrganizationDevices = async (apiKey, orgID, setter) => {
	let newDevices = {};
	await fetch(`https://merakiwifi.app/api/FetchOrganizationDevices`, {
		headers: {
			apikey: apiKey,
			orgid: orgID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Organization Devices Errors: ", data.errors[0]);
			}
			// console.log({ networkID: data });
			for (const device of data) {
				if (newDevices[device.networkId]) {
					newDevices[device.networkId].push(device);
				} else {
					newDevices[device.networkId] = [];
					newDevices[device.networkId].push(device);
				}
			}
			setter(newDevices);
		});
};

const fetchNetworkWirelessSsids = async (apiKey, networkID, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchNetworkWirelessSsids`, {
		headers: {
			apikey: apiKey,
			networkID: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Wireless Ssids Errors: ", data.errors[0]);
				setter(data);
				return;
			}

			setter((current) => ({
				...current,
				[networkID]: data.filter((ssid) => (ssid.enabled ? ssid : null)),
			}));
		});
};

const fetchNetworkWirelessRfProfiles = async (apiKey, networkID, setter) => {
	await fetch(`https://merakiwifi.app/api/FetchNetworkWirelessRfProfiles`, {
		headers: {
			apikey: apiKey,
			networkid: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log(
					"Fetch Network Wireless Rf Profiles Errors: ",
					data.errors[0]
				);
				setter(data);
				return;
			}
			// console.log(data);

			setter((current) => ({ ...current, [networkID]: data }));
		});
};

const fetchDeviceWirelessRadioSettings = async (apiKey, devices, setter) => {
	let settings = [];
	// console.log(devices);
	for (const device of devices) {
		if (["MR", "CW"].includes(device.model.slice(0, 2))) {
			await fetch(
				`https://merakiwifi.app/api/FetchDeviceWirelessRadioSettings`,
				{
					headers: {
						apikey: apiKey,
						serial: device.serial,
					},
				}
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.errors) {
						console.log(
							"Fetch Device Wireless Raido Settings Errors: ",
							data.errors[0]
						);
					}
					settings.push(data);
				});
		}
	}
	// console.log(settings);
	// console.log(devices);
	setter((current) => ({ ...current, [devices[0].networkId]: settings }));
};

const fetchAPConfig = async (
	apiKey,
	networkWirelessSsids,
	networkIDs,
	devices,
	rfProfiles,
	deviceRadioSettings,
	setter
) => {
	let configs = [];
	for (const device of devices) {
		// console.log(device)
		if (["MR", "CW"].includes(device.model.slice(0, 2))) {
			const data = await fetch(`https://merakiwifi.app/api/FetchAPConfig`, {
				headers: {
					apikey: apiKey,
					deviceSerial: device.serial,
				},
			}).then((response) => response.json());
			if (data.errors) {
				console.log("Fetch AP Config Errors: ", data.errors[0]);
				return;
			}
			console.log(rfProfiles);
			let rfProfileId = rfProfiles.find(
				(lookup) =>
					lookup.id ===
					deviceRadioSettings.find((lookup) => lookup.serial === device.serial)
						.rfProfileId
			);

			let bands = new Set();
			for (const ssid of data.basicServiceSets) {
				if (rfProfileId) {
					if (
						rfProfileId.twoFourGhzSettings &&
						rfProfileId.twoFourGhzSettings.validAutoChannels.includes(
							ssid.channel
						)
					) {
						// console.log("the 2.4 band includes channel ", ssid.channel);
						bands.add("2.4");
					}
					if (
						rfProfileId.fiveGhzSettings &&
						rfProfileId.fiveGhzSettings.validAutoChannels.includes(ssid.channel)
					) {
						// console.log("the 5 band includes channel ", ssid.channel);
						bands.add("5");
					}
					if (
						rfProfileId.sixGhzSettings &&
						rfProfileId.sixGhzSettings.validAutoChannels.includes(ssid.channel)
					) {
						// console.log("the 6 band includes channel ", ssid.channel);
						bands.add("6");
					}
				}
			}
			// console.log(data.basicServiceSets);
			// console.log(bands);
			let ssidCount = { "2.4 GHz": 0, "5 GHz": 0, "6 GHz": 0 };
			if (rfProfileId) {
				for (const ssid of Object.values(rfProfileId.perSsidSettings)) {
					if (
						networkWirelessSsids.some((lookup) => lookup.name === ssid.name)
					) {
						for (const band of bands) {
							if (ssid.bands.enabled.includes(band)) {
								ssidCount[band + " GHz"] += 1;
							}
						}
					}
				}
			}

			//For each ssid test if we have a power associated with the band value, and if we dont, add one to the "power" object.
			let power = {};
			for (const ssid of data.basicServiceSets) {
				if (!power[ssid.band]) {
					power[ssid.band] = ssid.power;
				}
			}
			// let channelsInRange = false
			// if(networkDevices.filter(lookup => ["MR", "CW"].includes(lookup.model.slice(0, 2))).length < 25 && rfProfileId.fiveGhzSettings.validAutoChannels.length <)

			let minBitrate = {};
			let minBitrateType = "";
			if (rfProfileId) {
				if (rfProfileId.minBitrateType === "band") {
					minBitrateType = "band";
					minBitrate = {
						"2.4 GHz": rfProfileId.twoFourGhzSettings.minBitrate,
						"5 GHz": rfProfileId.fiveGhzSettings.minBitrate,
						"6 GHz": rfProfileId.sixGhzSettings.minBitrate,
					};
				} else if (rfProfileId.minBitrateType === "ssid") {
					minBitrateType = "ssid";
				}
			}
			let networkID = device.networkId;
			configs.push({
				networkID: networkID,
				networkName: networkIDs.find((lookup) => lookup.id === networkID).name,
				deviceMac: device.mac,
				deviceName: device.name,
				deviceModel: device.model,
				deviceFirmware: device.firmware,
				channels: [
					...new Set(
						data.basicServiceSets.map((ssid) => {
							return ssid.channel;
						})
					),
				].join(", "),
				channelWidths: Object.fromEntries([
					...new Set(
						data.basicServiceSets.map((ssid) => {
							return [ssid.band, ssid.channelWidth];
						})
					),
				]),
				power: power,
				clientBalancingEnabled: rfProfileId
					? rfProfileId.clientBalancingEnabled
					: false,
				minBitrate: minBitrate,
				minBitrateType: minBitrateType,
				ssidCount: ssidCount,
				rfProfileId: rfProfileId && rfProfileId.id,
				rfProfileName:
					(rfProfileId && rfProfileId.name) ||
					(networkIDs.find((lookup) => lookup.id === networkID)
						.isBoundToConfigTemplate &&
						"Using Template"),
			});
		}
	}
	setter((current) => ({ ...current, [devices[0].networkId]: configs }));
};

function WifiConfigAudit() {
	const [apiKey, setApiKey] = useState("");
	const [apiKeyHolder, setApiKeyHolder] = useState();
	const [orgIDs, setOrgIds] = useState([]);
	const [selectedOrgID, setSelectedOrgID] = useState(0);
	const [networkIDs, setNetworkIDs] = useState([]);
	const [selectedNetworkID, setSelectedNetworkID] = useState();
	const [networkDevices, setNetworkDevices] = useState({});
	const [wifiConfigs, setWifiConfigs] = useState([]);
	const [rfProfiles, setRfProfiles] = useState({});
	// const [activeRfProfiles, setActiveRfProfiles] = useState([]);
	const [activeNetworkID, setActiveNetworkID] = useState();
	const [deviceRadioSettings, setDeviceRadioSettings] = useState({});
	const [auditStarted, setAuditStarted] = useState(false);
	const [auditFinished, setAuditFinished] = useState(false);
	const [bestPracticeConfig, setBestPracticeConfig] = useState({
		channels: {
			"UNII-1": [36, 40, 44, 48],
			"UNII-2": [52, 56, 60, 64],
			"UNII-2-Extended": [
				100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144,
			],
			"UNII-3": [149, 153, 157, 161, 165],
		},
		channelWidths: { "2.4 GHz": "20MHz", "5 GHz": "20MHz", "6 GHz": "20MHz" },
		minPowers: { "2.4 GHz": "5", "5 GHz": "14", "6 GHz": "14" },
		maxPowers: { "2.4 GHz": "8", "5 GHz": "17", "6 GHz": "17" },
		minBitrate: { "2.4 GHz": "11", "5 GHz": "12", "6 GHz": "12" },
		clientBalancingEnabled: false,
	});
	// const [ssidClientCounts, setSsidClientCounts] = useState([]);
	const [networkWirelessSsids, setNetworkWirelessSsids] = useState({});
	const [csvData, setCsvData] = useState([]);

	const auditWifiConfig = (config) => {
		let audit = [];
		// console.log(config);
		let apCount = networkDevices[config.networkID].filter((lookup) =>
			["MR", "CW"].includes(lookup.model.slice(0, 2))
		).length;
		for (const channelWidth of Object.entries(config.channelWidths)) {
			if (
				parseInt(channelWidth[1].split(" MHz")[0]) !==
				parseInt(bestPracticeConfig.channelWidths[channelWidth[0]])
			) {
				audit.push({ type: "channelWidth", error: channelWidth[0] });
			}
		}
		for (const power of Object.entries(config.power)) {
			if (
				parseInt(power[1].split(" dBm")[0]) <
					bestPracticeConfig.minPowers[power[0]] ||
				parseInt(power[1].split(" dBm")[0]) >
					bestPracticeConfig.maxPowers[power[0]]
			) {
				audit.push({ type: "power", error: power[0] });
			}
			if (config.minBitrateType === "band") {
				// console.log(config.minBitrate);
				for (const rate of Object.entries(config.minBitrate)) {
					if (
						parseInt(rate[1]) > parseInt(bestPracticeConfig.minBitrate[rate[0]])
					) {
						audit.push({ type: "minBitrate", error: rate[0] });
					}
				}
			}
			if (
				config.clientBalancingEnabled !==
				bestPracticeConfig.clientBalancingEnabled
			) {
				audit.push({ type: "clientBalancing", error: "true" });
			}
			let maxChannelCount = 0;
			for (const channel of Object.values(bestPracticeConfig.channels)) {
				maxChannelCount += channel.length;
			}
			if (config.rfProfileId) {
				if (
					(apCount < maxChannelCount &&
						rfProfiles[config.networkID].find(
							(lookup) => lookup.id === config.rfProfileId
						).fiveGhzSettings.validAutoChannels.length < apCount) ||
					(apCount > maxChannelCount &&
						rfProfiles[config.networkID].find(
							(lookup) => lookup.id === config.rfProfileId
						).fiveGhzSettings.validAutoChannels.length < maxChannelCount)
				) {
					audit.push({ type: "channels", error: "true" });
				}
			}
		}
		return audit;
	};
	// useEffect(() => {
	// 	let newCsvData = [];
	// 	if (networkEvents.message === null) {
	// 		networkEvents.events.map((networkEvent) => {
	// 			newCsvData.push([
	// 				networkEvent.occurredAt,
	// 				networkEvent.deviceName,
	// 				networkEvent.ssidName,
	// 				networkEvent.clientDescription,
	// 				networkEvent.clientMac,
	// 				networkEvent.category,
	// 				networkEvent.type,
	// 				Object.keys(networkEvent.eventData)
	// 					.map(function (key) {
	// 						return key + ":" + networkEvent.eventData[key];
	// 					})
	// 					.join(" "),
	// 			]);
	// 		});
	// 	}
	// 	setCsvData([
	// 		["Date/Time", "Access Point", "SSID", "Client Name", "Mac Address", "Event Category", "Event Type", "Details"],
	// 		...newCsvData,
	// 	]);
	// }, [networkEvents]);

	useEffect(() => {
		setSelectedOrgID(0);
		setNetworkIDs([]);
		setWifiConfigs([]);
		setNetworkDevices([]);
		setRfProfiles([]);
		setNetworkWirelessSsids([]);
	}, [apiKey]);

	useEffect(() => {
		if (apiKey && selectedOrgID !== 0) {
			fetchNetworkIDs(apiKey, selectedOrgID, setNetworkIDs);
			// fetchOrganizationDevices(apiKey, orgID, setNetworkDevices);
		}
	}, [apiKey, selectedOrgID]);

	useEffect(() => {
		// console.log(activeNetworkID);
		if (apiKey && activeNetworkID) {
			// console.log(activeNetworkID);
			fetchNetworkDevices(apiKey, activeNetworkID, setNetworkDevices);
			fetchNetworkWirelessRfProfiles(apiKey, activeNetworkID, setRfProfiles);
			fetchNetworkWirelessSsids(
				apiKey,
				activeNetworkID,
				setNetworkWirelessSsids
			);

			// setNetworkDevices([]);
			// setRfProfiles([]);
			// setNetworkWirelessSsids([]);
			// setWifiConfigs([]);
			// setDeviceRadioSettings([]);
		}
	}, [apiKey, activeNetworkID]);

	// useEffect(() => {
	// 	//this starts the prossesing chain by setting the active network id
	// 	if (networkIDs.length) {
	// 		setActiveNetworkID(networkIDs[0].id);
	// 	}
	// }, [networkIDs]);

	useEffect(() => {
		if (
			wifiConfigs[activeNetworkID] &&
			networkDevices[activeNetworkID].filter((lookup) =>
				["MR", "CW"].includes(lookup.model.slice(0, 2))
			).length === wifiConfigs[activeNetworkID].length
		) {
			if (
				networkIDs.findIndex((lookup) => lookup.id === activeNetworkID) + 1 <
				networkIDs.length
			) {
				setActiveNetworkID(
					(current) =>
						networkIDs[
							networkIDs.findIndex((lookup) => lookup.id == current) + 1
						].id
				);
				console.log("moiving to next network");
			} else {
				setAuditFinished(true);
				console.log("finnished");
			}
		}
	}, [networkDevices, activeNetworkID, wifiConfigs, networkIDs]);

	useEffect(() => {
		// console.log("hit");
		if (networkDevices[activeNetworkID]) {
			// console.log(networkDevices[activeNetworkID]);
			fetchDeviceWirelessRadioSettings(
				apiKey,
				networkDevices[activeNetworkID],
				setDeviceRadioSettings
			);
		}
	}, [apiKey, networkDevices, activeNetworkID]);

	useEffect(() => {
		if (
			rfProfiles[activeNetworkID] &&
			deviceRadioSettings[activeNetworkID] &&
			networkWirelessSsids[activeNetworkID]
		) {
			fetchAPConfig(
				apiKey,
				networkWirelessSsids[activeNetworkID],
				networkIDs,
				networkDevices[activeNetworkID],
				rfProfiles[activeNetworkID],
				deviceRadioSettings[activeNetworkID],
				setWifiConfigs
			);
		}
	}, [
		apiKey,
		rfProfiles,
		activeNetworkID,
		networkWirelessSsids,
		networkIDs,
		networkDevices,
		deviceRadioSettings,
	]);
	return (
		<div className="App">
			<ConfigAuditSideBar
				bestPracticeConfig={bestPracticeConfig}
				setBestPracticeConfig={setBestPracticeConfig}
				orgIDs={orgIDs}
				selectedOrgID={selectedOrgID}
				setSelectedOrgID={setSelectedOrgID}
			/>
			<div className="MainPanel">
				<TopBar
					fetchOrgIDs={fetchOrgIDs}
					setOrgIDs={setOrgIds}
					apiKey={apiKey}
					setApiKey={setApiKey}
					fetchAPIKeyHolder={fetchAPIKeyHolder}
					apiKeyHolder={apiKeyHolder}
					setApiKeyHolder={setApiKeyHolder}
				></TopBar>
				<SelectableTable
					title="Networks"
					searchable
					options={
						!auditStarted
							? networkIDs.map((nid) => {
									return {
										label: nid.name,
										customComponent: (
											<div
												className={css`
														display: flex;
														border-radius: 6px;
														border: 2px solid #64b348;
														margin-bottom: 6px;
														width: 100%;
														// justify-content: space-around;
														}
													`}
											>
												<span
													className={css`
														width: 80%;
														background-color: rgba(255, 255, 255, 0);
														color: black;
														&:hover {
															background-color: #64b348;
														}
													`}
												>
													{nid.name}
												</span>
												<span
													className={css`
														width: 20%;
														height: 100%;
														border-left: 2px solid #64b348;
														background-color: rgba(255, 255, 255, 0);
														color: black;
														&:hover {
															background-color: #64b348;
														}
													`}
													onClick={() => {
														if (networkIDs.length > 1) {
															setNetworkIDs((current) => [
																...current.filter(
																	(lookup) => lookup.id !== nid.id
																),
															]);
														}
													}}
												>
													x
												</span>
											</div>
										),
									};
							  })
							: networkIDs.map((nid) => {
									return {
										label: nid.name,
										details: {
											nid: nid,
										},
										passes: () => {
											if (wifiConfigs[nid.id]) {
												if (
													wifiConfigs[nid.id].some(
														(config) => auditWifiConfig(config).length
													)
												) {
													return false;
												} else {
													return true;
												}
											} else {
												return "incomplete";
											}
										},
									};
							  })
					}
					selectedItem={(item) => {
						if (auditStarted) {
							return item.details.nid.id === selectedNetworkID;
						}
					}}
					itemOnCick={(item) => {
						// console.log(item);
						if (auditStarted) {
							setSelectedNetworkID(item.details.nid.id);
						}
					}}
				>
					<div
						className={css`
							width: 100%;
							display: flex;
							justify-content: right;
						`}
					>
						{!auditStarted && (
							<Button
								style={{ fontSize: "12px" }}
								onClick={() => {
									if (networkIDs.length) {
										setActiveNetworkID(networkIDs[0].id);
										setAuditStarted(true);
									}
								}}
							>
								Run Audit
							</Button>
						)}
						{auditStarted && !auditFinished && (
							<div
								className={css`
									width: 20px;
									height: 20px;
									border: 3px solid #f3f3f3; /* Light grey */
									border-top: 3px solid #64b348; /* Black */
									border-radius: 50%;
									animation: spinner 1.5s linear infinite;

									@keyframes spinner {
										0% {
											transform: rotate(0deg);
										}
										100% {
											transform: rotate(360deg);
										}
									}
								`}
							/>
						)}
					</div>
				</SelectableTable>
				{/* 				
				networkIDs.some((lookup) => lookup.id === selectedNetworkID) &&
							networkIDs.find((lookup) => lookup.id === selectedNetworkID)
								.isBoundToConfigTemplate
								? networkIDs.find((lookup) => lookup.id === selectedNetworkID)
										.configTemplateId
								:  */}
				<WifiConfigTable
					configData={wifiConfigs[selectedNetworkID]}
					// bestPracticeConfig={bestPracticeConfig}
					auditFunction={(config) => {
						return auditWifiConfig(config);
					}}
				/>
				{/* {Object.values(rfProfiles).length ? (
					<span
						className={css`
							color: white;
							text-align: left;
							width: 100%;
							margin-top: 6px;
							font-size: 20px;
						`}
					>
						SSID Configs:
					</span>
				) : (
					""
				)} */}
				{/* {Object.values(rfProfiles).map((profile) => {
					if (
						(profile.bandSelectionType === "ssid" ||
							profile.minBitrateType === "ssid") &&
						wifiConfigs.some(
							(lookup) =>
								lookup.rfProfileId === profile.name ||
								lookup.rfProfileId === profile.id
						)
					) {
						return (
							<Table key={profile.id} title={profile.name} collapsible>
								<thead>
									<tr>
										<th scope="col">SSID #</th>
										<th scope="col">SSID Name</th>
										{profile.minBitrateType === "ssid" && (
											<th scope="col">Min. Bitrate</th>
										)}
										{profile.bandSelectionType === "ssid" && (
											<th scope="col">Band Selection</th>
										)}
									</tr>
								</thead>
								<tbody>
									{networkWirelessSsids.map((ssid, i) => {
										if (
											Object.values(profile.perSsidSettings).some(
												(lookup) => lookup.name === ssid.name
											)
										) {
											return (
												<tr key={i}>
													<td>{ssid.number}</td>
													<td>{ssid.name}</td>
													{profile.minBitrateType === "ssid" && (
														<td>{ssid.minBitrate}</td>
													)}
													{profile.bandSelectionType === "ssid" && (
														<td>
															{Object.values(profile.perSsidSettings)
																.find((lookup) => lookup.name === ssid.name)
																.bands.enabled.join(", ")}
														</td>
													)}
												</tr>
											);
										}
									})}
								</tbody>
							</Table>
						);
					}
				})} */}
				<div className="Footer">
					<Disclaimer
						label={
							<a href="#" style={{ color: "white" }}>
								About
							</a>
						}
						title="About:"
						popupLabel={
							<div>
								<span>
									As a Wi-Fi network engineer who's debugged a lot of Meraki
									Wi-Fi over the years, I've always wanted a way to see the
									pertinent Wi-Fi related configs and the event logs on the same
									screen. And when my son graduated from high school this year
									and was looking for a software development job for the summer,
									I thought "perfect opportunity." This has been a lot of fun to
									work on together, and it amazes me what my 18-year-old son is
									capable of. We hope you find this site useful.
								</span>
								<div
									style={{ display: "flex", justifyContent: "space-around" }}
								>
									<a
										href="https://www.linkedin.com/in/todd-smith-wifi/"
										target="_blank"
									>
										<img
											src={linkedin_icon}
											alt=""
											style={{
												width: "40px",
												height: "40px",
												marginRight: "6px",
											}}
										/>
										Todd Smith
									</a>

									<a
										href="https://www.linkedin.com/in/asher-smith-6baa3a220/"
										target="_blank"
									>
										<img
											src={linkedin_icon}
											alt=""
											style={{
												width: "40px",
												height: "40px",
												marginRight: "6px",
											}}
										/>
										Asher Smith
									</a>
								</div>
							</div>
						}
					/>
					|
					<Disclaimer
						label={
							<a href="#" style={{ color: "white" }}>
								Contact
							</a>
						}
						title="Contact:"
						popupLabel={
							<span>
								If you have any questions, comments, suggestions, or feature
								requests, please email. Email:{" "}
								<a href="mailto: todd@wifi-fu.com" target="_blank">
									todd@wifi-fu.com
								</a>
							</span>
						}
					/>
					|
					<Disclaimer
						label={
							<a href="#" style={{ color: "white" }}>
								Terms of Use
							</a>
						}
						title="Terms of Use:"
						popupLabel={
							<ul>
								<li>
									<span>
										<strong>Privacy Policy:</strong> No information is stored
										outside of the running instance of this Site. Although
										information is not stored, we do not guarantee that Internet
										transmissions will always be completely private or secure.
										This Site relies upon the Cisco Meraki Dashboard API for
										retrieving a Meraki Network. Access granted to the Meraki
										network is governed by the API key created by the
										administrator of the given network.
									</span>
								</li>
								<li>
									<span>
										<strong>Terms of Use:</strong> The following terms and
										conditions (“Terms of Use”) govern your use of this Site. BY
										USING THE SITE IN ANY WAY, YOU ACKNOWLEDGE THAT YOU HAVE
										READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF
										USE. IF YOU DO NOT AGREE, DO NOT USE THE SITE. This Site is
										free for public use with no guarantees or official support.
										We reserve the right, in our sole discretion and without any
										liability, to make changes to the Site and any Site Content,
										wholly or partly, at any time without prior notice. We make
										no commitment to update the Site regularly and therefore
										some Site Content may be out of date.
									</span>
								</li>
								<li>
									<span>
										<strong>Usage Restrictions:</strong>You must comply with all
										applicable laws when using the Site. Except as may be
										expressly permitted by applicable law or expressly permitted
										by us in writing, you will not, and will not permit anyone
										else to: (a) store, copy, modify, distribute, or resell any
										Site Content or compile or collect any Site Content as part
										of a database or other work; (b) use any automated tool to
										use the Site or store, copy, modify, distribute, or resell
										any Site Content; (c) use the Site and Site Content for any
										purpose except as agreed to in these Terms of Use or upload
										any User Content in any manner that may infringe any
										intellectual property right, proprietary right, or property
										right of us or any third parties; (d) circumvent or disable
										any digital rights management, usage rules, or other
										security features of the Site; (e) reproduce, modify,
										translate, enhance, decompile, disassemble, reverse
										engineer, or create derivative works of the Site or Site
										Content; (f) use the Site in a manner that threatens the
										integrity, performance, or availability of the Site; or (g)
										remove, alter, or obscure any portion of the Site or Site
										Content.
									</span>
								</li>
								<li>
									<span>
										<strong>Disclaimer of Warranties:</strong> YOUR USE OF THE
										SITE, THE SITE CONTENT, INCLUDING ANY SUBMISSION BY YOU OR
										ANYONE USING YOUR API KEY(S), AND RESULTS YOU RECEIVE FROM
										THE SITE, IS AT YOUR SOLE DISCRETION AND RISK. THE SITE AND
										SITE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
										BASIS. WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR
										USEFULNESS OF THE SITE OR SITE CONTENT, OR THAT THE SITE
										WILL BE UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, SECURE,
										TIMELY OR THAT ANY DEFECT WILL BE CORRECTED. YOU WILL BE
										SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER, COMUTPER
										NETWORK, OR LOSS OF DATA THAT RESULTS FROM USE OF THE SITE.
										YOUR SOLE REMEDY FOR ANY DISSATISFACTION WITH THE SITE OR
										ANY SITE CONTENT IS TO CEASE USING THE SITE OR THE SITE
										CONTENT.
									</span>
								</li>
								<li>
									<span>
										<strong>Limitation of Liability:</strong> IN NO EVENT SHALL
										WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
										INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR EXEMPLARY
										DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
										PROFITS OR REVENUES, BUSINESS INTERRUPTION, GOODWILL, USE,
										DATA OR OTHER INTANGIBLE LOSSES ARISING FROM YOUR USE AND
										ACCESS OF THE SITE AND SITE CONTENT, OR DAMAGES RELATED TO
										YOUR RELIANCE ON ANY INFORMATION OBTAINED FROM THE SITE.
										THIS SECTION APPLIES WHETHER DAMAGES ARE BASED ON WARRANTY,
										CONTRACT, TORT, STATUTE, OR ANY OTHER LEGAL THEORY.
									</span>
								</li>
							</ul>
						}
					/>
					|
					<Disclaimer
						label={
							<a href="#" style={{ color: "white" }}>
								Non-Affiliation Disclaimer
							</a>
						}
						title="Non-Affiliation Disclaimer:"
						popupLabel={
							<span>
								This site is not affiliated, associated, authorized, endorsed
								by, or in any way officially connected with Cisco Meraki. The
								use of any trade name or trademark is for identification and
								reference purposes only and does not imply any association with
								the trademark holder of their product brand.
							</span>
						}
					/>
					|
					<Disclaimer
						label={
							<a href="#" style={{ color: "white" }}>
								Tip Us
							</a>
						}
						title={"Finding this site useful? Leave a tip:"}
						popupLabel={
							<div className="LeaveATip_prompt">
								<div>
									<a href="https://ko-fi.com/merakiwifiapp" target="_blank">
										<img src={kofi_tip_icon} alt="" style={{ width: "100%" }} />
									</a>
									<a href="https://venmo.com/todd-smith-999" target="_blank">
										<img
											src={venmo_tip_icon}
											alt=""
											style={{ width: "100%" }}
										/>
									</a>
								</div>
								<span>
									Tips go towards continued support and new feature development.
									Thanks!
								</span>
							</div>
						}
					/>
				</div>
			</div>
		</div>
	);
}

export default WifiConfigAudit;
