import { css } from "@emotion/css";
const Item = ({ data, selectedItem, disabled, onClick, style }) => {
	if (data.customComponent) {
		return data.customComponent;
	} else {
		if (!data.passes) {
			data.passes = () => {
				return "incomplete";
			};
		}
		return (
			<div
				className={css`
					display: flex;
					border-radius: 6px;
					border: 2px solid
						${Object.keys(data).includes("passes") &&
						data.passes() === "incomplete"
							? "white"
							: data.passes()
							? "#64b348"
							: "red"};
					margin-bottom: 6px;
					width: 100%;
					justify-content: space-around;
					background-color: ${selectedItem(data)
						? "#696969"
						: "rgba(255, 255, 255, 0)"};
					color: black;
					&:hover {
						background-color: #64b348;
					}
				`}
				// style={selectedItem(data) ? { backgroundColor: "#696969" } : null}
				onClick={() => {
					onClick(data);
				}}
			>
				<span>{data.label}</span>
				{data.details.removable ? (
					Object.keys(data.details).includes("removalFunction") ? (
						<span
							className={css`
								// margin-left;
								// right: 12px;
								height: 100%;
								width: 20px;
								border-left: 2px solid white;
							`}
							onClick={() => {
								data.details.removalFunction(data);
							}}
						>
							x
						</span>
					) : (
						<span>NO REMOVAL FUNCTION</span>
					)
				) : (
					""
				)}
			</div>
		);
	}
};

export default Item;
