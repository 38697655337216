// import React, { useEffect, useState } from 'react'
import Table from "react-bootstrap/Table";
import Item from "./Table/Item";
import { CSVLink } from "react-csv";
import info_icon from "./assets/images/info_icon.png";
// import 'bootstrap/dist/css/bootstrap.css';

const NetworkEventsTable = ({ networkEvents, csvData, timezoneOffset = 0 }) => {
	return (
		<div className="NetworkEventsTable">
			<div className="TableTitle">
				<span>Event Logs</span>
				<CSVLink className="ExportButton" data={csvData} filename={"Event-Logs-Download.csv"}>
					Export to CSV
				</CSVLink>
			</div>
			<Table size="sm" striped hover>
				<thead>
					<tr>
						<th scope="col">
							<span>Date/Time</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "occurredAt" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Access Point</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "deviceName" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>SSID</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "ssidName" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Client Name</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "clientDescription" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Client MAC</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "clientMac" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Band</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "band" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Event Category</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "category" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Event Type</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title='Use "type" in your custom filters'
              ></img> */}
						</th>
						<th scope="col">
							<span>Details</span>
							{/* <img
                src={info_icon}
                className="moreInfoIcon"
                data-toggle="tooltip"
                title="Use the specific element name as shown below in your custom filters"
              ></img> */}
						</th>
					</tr>
				</thead>
				<tbody>
					{networkEvents.message === null
						? networkEvents.events.map((networkEvent, i) => {
								let currentDate = new Date(networkEvent.occurredAt);
								let band;
								if (networkEvent.eventData.band) {
									if (networkEvent.eventData.band === "2") {
										band = "2.4";
									} else if (networkEvent.eventData.band === "6") {
										band = "6";
									} else {
										band = "5";
									}
								} else if (networkEvent.eventData.radio) {
									if (networkEvent.eventData.radio === "0") {
										band = "2.4";
									} else if (networkEvent.eventData.radio === "2") {
										band = "6";
									} else {
										band = "5";
									}
								}
								//code to adjust date to local time
								// currentDate.timezoneOffset = timezoneOffset;
								currentDate.setMinutes(currentDate.getMinutes() + timezoneOffset + currentDate.getTimezoneOffset());
								return (
									<tr key={i}>
										{/* Retreve and parse the date and time */}
										<td>{currentDate.toLocaleString()}</td>
										<td>{networkEvent.deviceName}</td>
										<td>{networkEvent.ssidName}</td>
										<td>{networkEvent.clientDescription}</td>
										<td>{networkEvent.clientMac}</td>
										<td>{band}</td>
										<td>{networkEvent.category}</td>
										<td>{networkEvent.type}</td>
										<Item
											data={Object.keys(networkEvent.eventData)
												.map((key) => {
													return key + ":" + networkEvent.eventData[key];
												})
												.join(" \n")}
											collapsible
										></Item>
										{/* <td>
											<div className="EventDataHolder">
												<div className="HoverForDetailPrompt">
													<span>
														{Object.keys(networkEvent.eventData)
															.map((key, i) => {
																return i < 2 ? key + ":" + networkEvent.eventData[key] : null;
															})
															.join(" \n")}
													</span>
													<a href="#"> {Object.keys(networkEvent.eventData).length > 2 ? "more..." : ""}</a>
												</div>

												<span>
													{Object.keys(networkEvent.eventData)
														.map((key, i) => {
															return key + ":" + networkEvent.eventData[key];
														})
														.join(" \n")}
												</span>
											</div>
										</td> */}
									</tr>
								);
						  })
						: null}
				</tbody>
			</Table>
		</div>
	);
};

export default NetworkEventsTable;
