// import React, { useEffect, useState } from 'react'

import { useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import arrow_icon from "./assets/images/arrow_icon.png";

const SSIDConfigTable = ({ ssidConfigData, collapsed, setCollapsed }) => {
	// console.log(ssidConfigData);
	return (
		<div className="SSIDConfigTable">
			<div className="TableTitle">
				<span>SSIDs</span>
				<img
					src={arrow_icon}
					className="collapseArrow"
					collapsed={collapsed ? "true" : null}
					alt=""
					onClick={() => {
						setCollapsed(!collapsed);
					}}
				/>
			</div>
			<Table
				size="sm"
				striped
				hover
				style={collapsed ? { display: "none" } : {}}
			>
				<thead>
					<tr>
						<th scope="col">SSID #</th>
						<th scope="col">SSID Name</th>
						<th scope="col">SSID Broadcast</th>
						<th scope="col">Security Type</th>
						<th scope="col">Security Mode</th>
						<th scope="col">Min. Bitrate</th>
						<th scope="col">Mandatory DHCP</th>
						<th scope="col">802.11 r/w</th>
						<th scope="col">VLAN ID</th>
						<th scope="col">Client Count</th>
						<th scope="col">Connectivity Issues</th>
					</tr>
				</thead>
				<tbody>
					{ssidConfigData
						? ssidConfigData.map((config, i) => {
								// console.log(config);
								return (
									<tr key={i}>
										{/* Retreve and parse the date and time */}
										<td>{config.number.toString()}</td>
										<td>{config.name}</td>
										<td>{config.visible ? "enabled" : "disabled"}</td>
										<td>
											{config.encryptionMode
												? config.encryptionMode
												: config.authMode}
										</td>
										<td>{config.wpaEncryptionMode}</td>
										<td>{config.minBitrate}</td>
										<td>
											{config.mandatoryDhcpEnabled ? "enabled" : "disabled"}
										</td>
										<td>
											{config.dot11r && config.dot11r.enabled
												? "enabled"
												: "disabled"}
											/
											{config.dot11r && config.dot11w.enabled
												? config.dot11w.required
													? "required"
													: "enabled"
												: "disabled"}
										</td>
										<td>
											{config.useVlanTagging ? config.defaultVlanId : "none"}
										</td>
										<td>{config.clientCount}</td>
										<td title={Object.entries(config.stats).join("\n")}>
											{Object.values(config.stats).reduce((a, b) => a + b, 0) -
												config.stats.success}
										</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</Table>
		</div>
	);
};

export default SSIDConfigTable;
