// import React, { useEffect, useState } from 'react'

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import trashcan_icon from "./assets/images/trashcan_icon.png";
import plus_icon from "./assets/images/plus_icon1.png";
import arrow_icon from "./assets/images/arrow_icon.png";
import app_logo from "./assets/images/app_logo.png";
import networks_icon from "./assets/images/networks_icon.png";
import ap_icon from "./assets/images/ap_icon.png";
import ssid_icon from "./assets/images/ssid_icon.png";
import include_event_icon from "./assets/images/include_event_icon.png";
import exclude_event_icon from "./assets/images/exclude_event_icon.png";
import client_device_icon from "./assets/images/client_device_icon.png";
import time_start_icon from "./assets/images/time_start_icon.png";
import time_stop_icon from "./assets/images/time_stop_icon.png";
import custom_filter_icon from "./assets/images/custom_filter_icon.png";
import time_zone_icon from "./assets/images/time_zone_icon.png";
import radio_icon from "./assets/images/radio_icon.png";
import info_icon from "./assets/images/info_icon.png";
import DatePicker from "react-datepicker";
import Dropdown from "./Dropdown";
import "react-datepicker/dist/react-datepicker.css";

const DebugDashboardSideBar = ({
	timezone,
	setTimezone,
	fetchNetworkWirelessConnectionStats,
	networkWirelessConnectionStats,
	setNetworkWirelessConnectionStats,
	fetchSwitchPortNumbers,
	switchPortNumbers,
	setSwitchPortNumbers,
	fetchNetworkHealth,
	networkHealth,
	setNetworkHealth,
	fetchDeviceConnectionStats,
	deviceConnectionStats,
	setDeviceConnectionStats,
	fetchSSIDClientCounts,
	ssidClientCounts,
	setSsidClientCounts,
	fetchDeviceClientCounts,
	deviceClientCounts,
	setDeviceClientCounts,
	fetchDeviceStatuses,
	deviceStatuses,
	setDeviceStatuses,
	apiKey,
	apiKeyHolder,
	setApiKeyHolder,
	fetchOrgIDs,
	orgIDs,
	setOrgIDs,
	selectedOrgID,
	setSelectedOrgID,
	fetchNetworkIDs,
	networkIDs,
	setNetworkIDs,
	networkID,
	setNetworkID,
	fetchNetworkClients,
	networkClients,
	setNetworkClients,
	selectedNetworkClients,
	setSelectedNetworkClients,
	fetchNetworkWirelessSsids,
	networkWirelessSsids,
	setNetworkWirelessSsids,
	fetchNetworkEventTypes,
	networkEventTypes,
	setNetworkEventTypes,
	selectedIncludedNetworkEventTypes,
	setSelectedIncludedNetworkEventTypes,
	selectedExcludedNetworkEventTypes,
	setSelectedExcludedNetworkEventTypes,
	fetchNetworkDevices,
	networkDevices,
	setNetworkDevices,
	getSsidConfigData,
	setSsidConfigData,
	fetchAPConfig,
	networkAPConfigs,
	setNetworkAPConfigs,
	customFilters,
	setCustomFilters,
	selectedCustomFilters,
	setSelectedCustomFilters,
	queryParams,
	setQueryParams,
	fetchNetworkEvents,
	setNetworkEvents,
	sidebarOpen = { sidebarOpen },
}) => {
	const [customFilterDropdownOpen, setCustomFilterDropdownOpen] =
		useState(false);
	const [startingAfter, setStartingAfter] = useState("");
	const [endingBefore, setEndingBefore] = useState("");
	const [newFilterText, setNewFilterText] = useState("");
	const [networkIDName, setNetworkIDName] = useState("");
	const [validEventTypes, setValidEventTypes] = useState({
		802.11: [],
		"802.1X": [],
		"AI-Enhanced RRM": [],
		"Air Marshal": [],
		Auth: [],
		AutoRF: [],
		DFS: [],
		DHCP: [],
		"Intrusion Detection": [],
		"IP conflict": [],
		RADIUS: [],
		WPA: [],
	});

	useEffect(() => {
		if (typeof orgIDs !== Array && orgIDs.length === 1) {
			console.log(orgIDs);
			setSelectedOrgID(orgIDs[0].id);
		}
	}, [orgIDs]);

	useEffect(() => {
		const getNetworkIDs = async () => {
			setNetworkIDs(await fetchNetworkIDs(apiKey, selectedOrgID));
		};

		if (apiKey && selectedOrgID !== 0) {
			getNetworkIDs();
		}
	}, [selectedOrgID]);

	useEffect(() => {
		const getNetworkWirelessSsids = async () => {
			setNetworkWirelessSsids(
				await fetchNetworkWirelessSsids(apiKey, networkID)
			);
		};
		if (apiKey && networkID) {
			getNetworkWirelessSsids();
		}
	}, [apiKey, networkID, fetchNetworkWirelessSsids, setNetworkWirelessSsids]);
	useEffect(() => {
		const getSSIDClientCounts = async () => {
			await setSsidClientCounts(
				await fetchSSIDClientCounts(apiKey, networkID, networkWirelessSsids)
			);
		};
		const getNetworkWirelessConnectionStats = async () => {
			setNetworkWirelessConnectionStats(
				await fetchNetworkWirelessConnectionStats(
					apiKey,
					networkID,
					networkWirelessSsids
				)
			);
		};
		getSSIDClientCounts();
		getNetworkWirelessConnectionStats();
	}, [networkWirelessSsids]);

	// useEffect(() => {
	// 	console.log(
	// 		"getting ssid config data",
	// 		ssidClientCounts,
	// 		networkWirelessConnectionStats
	// 	);
	// 	const getConfigs = async () => {
	// 		setSsidConfigData(
	// 			await getSsidConfigData(networkWirelessSsids, ssidClientCounts)
	// 		);
	// 	};
	// 	if (
	// 		(ssidClientCounts, networkWirelessSsids, networkWirelessConnectionStats)
	// 	) {
	// 		getConfigs();
	// 	}
	// }, [networkWirelessSsids, ssidClientCounts, networkWirelessConnectionStats]);

	useEffect(() => {
		const getNetworkHealth = async () => {
			setNetworkHealth(await fetchNetworkHealth(apiKey, networkID));
		};
		const getDeviceConnectionStats = async () => {
			setDeviceConnectionStats(
				await fetchDeviceConnectionStats(apiKey, networkID)
			);
		};
		const getDeviceStatuses = async () => {
			setDeviceStatuses(
				await fetchDeviceStatuses(apiKey, selectedOrgID, networkID)
			);
		};
		setNetworkAPConfigs([]);
		setNetworkEventTypes([]);
		if (networkID) {
			setSelectedCustomFilters([]);
			getNetworkHealth();
			getDeviceConnectionStats();
			getDeviceStatuses();
		}
	}, [networkID]);

	useEffect(() => {
		const getDeviceClientCounts = async () => {
			setDeviceClientCounts(
				await fetchDeviceClientCounts(apiKey, networkID, networkDevices)
			);
		};
		const getSwitchPortNumbers = async () => {
			setSwitchPortNumbers(
				await fetchSwitchPortNumbers(apiKey, networkDevices)
			);
		};
		getDeviceClientCounts();
		getSwitchPortNumbers();
	}, [networkDevices]);

	useEffect(() => {
		const getAPConfig = async () => {
			setNetworkAPConfigs(
				await fetchAPConfig(
					apiKey,
					networkDevices,
					switchPortNumbers,
					networkHealth,
					deviceConnectionStats,
					deviceClientCounts,
					deviceStatuses
				)
			);
		};
		// console.log(
		// 	`networkDevices:${networkDevices.length}, networkHealth: ${networkHealth.length}, deviceConnectionStats: ${deviceConnectionStats.length}, deviceClientCounts: ${deviceClientCounts.length}, deviceStatuses: ${deviceStatuses.length}, switchPortNumbers: ${switchPortNumbers.length}`
		// );
		if (
			networkDevices.length &&
			networkHealth.length &&
			deviceConnectionStats.length &&
			deviceClientCounts.length &&
			deviceStatuses.length &&
			switchPortNumbers.length
		) {
			console.log("Attempting to fetch ap configs");
			getAPConfig();
		}
	}, [
		switchPortNumbers,
		deviceClientCounts,
		networkHealth,
		deviceStatuses,
		deviceConnectionStats,
	]);

	useEffect(() => {
		const getNetworkEventTypes = async () => {
			setNetworkEventTypes(await fetchNetworkEventTypes(apiKey, networkID));
		};
		if (apiKey && networkID) {
			getNetworkEventTypes();
		}
	}, [apiKey, networkID, fetchNetworkEventTypes, setNetworkEventTypes]);

	useEffect(() => {
		setValidEventTypes({
			802.11: [],
			"802.1X": [],
			"AI-Enhanced RRM": [],
			"Air Marshal": [],
			Auth: [],
			AutoRF: [],
			DFS: [],
			DHCP: [],
			"Intrusion Detection": [],
			"IP conflict": [],
			RADIUS: [],
			WPA: [],
		});
		if (
			networkEventTypes.length &&
			!networkEventTypes.includes("Invalid API key")
		) {
			networkEventTypes.map((eventType) => {
				if (validEventTypes[eventType.category]) {
					validEventTypes[eventType.category].push(eventType);
				}
			});
			setValidEventTypes({ ...validEventTypes });
		}
	}, [networkEventTypes]);

	useEffect(() => {
		setQueryParams({});
		queryParams.includedEventTypes = selectedIncludedNetworkEventTypes;
		queryParams.excludedEventTypes = selectedExcludedNetworkEventTypes;
		queryParams.startingAfter = startingAfter || "";
		endingBefore && !startingAfter
			? (queryParams.endingBefore = endingBefore)
			: (queryParams.endingBefore = "");
		queryParams.endingBefore =
			endingBefore && !startingAfter ? endingBefore : "";
		setQueryParams({ ...queryParams });
		// setQueryParams((current) => Object.fromEntries(Object.entries(current).filter(([k, v]) => v.length > 0)))
	}, [
		selectedIncludedNetworkEventTypes,
		selectedExcludedNetworkEventTypes,
		startingAfter,
		endingBefore,
		selectedCustomFilters,
	]);

	useEffect(() => {
		const getNetworkEvents = async () => {
			setNetworkEvents(
				await fetchNetworkEvents(
					apiKey,
					networkID,
					queryParams,
					selectedCustomFilters
				)
			);
		};
		if (networkID) {
			getNetworkEvents();
		}
	}, [queryParams, networkID]);

	return (
		<div className={`SideBar ${sidebarOpen && "open"}`}>
			<a className="logoMain" href="https://merakiwifi.app/">
				<img src={app_logo} alt="Meraki Wifi Logo" />
			</a>
			<div
				className={css`
					display: flex;
					width: 100%;
					justify-content: space-around;
					margin-top: 12px;
					margin-bottom: 12px;
				`}
			>
				<Link to="/" className="NavigationButton">
					Debug Dashboard
				</Link>
				<Link to="/WifiConfigAudit" className="NavigationButton">
					Config Audit
				</Link>
			</div>
			<hr style={{ width: "100%", margin: "0px" }}></hr>
			{orgIDs.length > 1 && (
				<Dropdown
					title="Organizations:"
					options={orgIDs.map((orgID) => {
						return {
							label: orgID.name,
							details: orgID,
						};
					})}
					searchable={orgIDs.length > 10}
					icon={networks_icon}
					noDataAvailableLabel="No Organizations Available"
					selectPrompt="Select Organization ID:"
					selectedItem={(item) => {
						return item.details.id === selectedOrgID;
					}}
					itemOnChange={(item) => {
						setSelectedOrgID(item.details.id);
					}}
					itemDisplayerData={
						selectedOrgID && [
							{
								label: orgIDs.filter((orgID) => orgID.id === selectedOrgID)[0]
									.name,
							},
						]
					}
				/>
			)}
			<Dropdown
				title="Network:"
				options={networkIDs.map((networkID) => {
					return {
						label: networkID.name,
						details: networkID,
					};
				})}
				searchable={networkIDs.length > 10}
				icon={networks_icon}
				noDataAvailableLabel="No Networks Available"
				selectPrompt="Select Network ID:"
				selectedItem={(item) => {
					return item.label === networkIDName;
				}}
				itemOnChange={async (item) => {
					setNetworkID(item.details.id);
					setNetworkIDName(item.label);
					setNetworkClients(await fetchNetworkClients(apiKey, item.details.id));
					setNetworkDevices(await fetchNetworkDevices(apiKey, item.details.id));
				}}
				itemDisplayerData={networkID && [{ label: networkIDName }]}
			/>
			<hr style={{ width: "100%", margin: "0px" }}></hr>
			<span className="Title">Event Log Filters</span>
			<hr style={{ width: "100%", margin: "0px" }}></hr>
			<Dropdown
				title={"SSIDs:"}
				options={networkWirelessSsids.map((ssid) => {
					return {
						label: ssid.name,
					};
				})}
				noDataAvailableLabel="No SSIDs Available"
				selectPrompt={"Select Network SSID(s):"}
				icon={ssid_icon}
				checkable
				itemChecked={(item) =>
					selectedCustomFilters.includes("ssidName:" + item.label)
						? true
						: false
				}
				itemOnChange={(item) => {
					if (selectedCustomFilters.includes("ssidName:" + item.label)) {
						setSelectedCustomFilters(
							selectedCustomFilters.filter(
								(data, index) => data !== "ssidName:" + item.label
							)
						);
					} else {
						setSelectedCustomFilters([
							...selectedCustomFilters,
							"ssidName:" + item.label,
						]);
					}
				}}
				itemDisplayerData={selectedCustomFilters
					.filter((filter) => filter.split(":")[0] === "ssidName")
					.map((el) => {
						return {
							label: el.split(":")[1],
						};
					})}
				itemDisplayerOnClick={(item) => {
					setSelectedCustomFilters(
						selectedCustomFilters.filter(
							(data, index) => data !== "ssidName:" + item.label
						)
					);
				}}
			></Dropdown>
			<Dropdown
				title={"Access Points:"}
				searchable
				options={networkDevices
					.filter(
						(device) =>
							["MR", "CW"].includes(device.model.slice(0, 2)) &&
							deviceConnectionStats.some(
								(deviceStats) => deviceStats.serial === device.serial
							)
					)
					.map((device) => {
						return {
							label: device.mac,
							details: device,
						};
					})}
				noDataAvailableLabel="No APs Available"
				selectPrompt={"select AP(s):"}
				icon={ap_icon}
				checkable
				itemChecked={(item) =>
					selectedCustomFilters.includes("deviceSerial:" + item.details.serial)
						? true
						: false
				}
				itemOnChange={(item) => {
					if (
						selectedCustomFilters.includes(
							"deviceSerial:" + item.details.serial
						)
					) {
						setSelectedCustomFilters(
							selectedCustomFilters.filter(
								(data) => data !== "deviceSerial:" + item.details.serial
							)
						);
					} else {
						setSelectedCustomFilters([
							...selectedCustomFilters,
							"deviceSerial:" + item.details.serial,
						]);
					}
				}}
				itemDisplayerData={selectedCustomFilters
					.filter((filter) => filter.split(":")[0] === "deviceSerial")
					.map((el) => {
						return {
							label: networkDevices.find(
								(lookup) => lookup.serial === el.split(/:(.*)/s)[1]
							).mac,
							details: networkDevices.find(
								(lookup) => lookup.serial === el.split(/:(.*)/s)[1]
							),
						};
					})}
				itemDisplayerOnClick={(item) => {
					setSelectedCustomFilters(
						selectedCustomFilters.filter(
							(data) => data !== "deviceSerial:" + item.details.serial
						)
					);
				}}
			></Dropdown>

			<Dropdown
				title={"Radio:"}
				options={[
					{ label: "2.4 GHz", details: { radioNumber: 0 } },
					{ label: "5 Ghz", details: { radioNumber: 1 } },
					{ label: "6 GHz", details: { radioNumber: 2 } },
				]}
				selectPrompt={"Select Radio Frequency:"}
				icon={radio_icon}
				checkable
				itemChecked={(item) =>
					selectedCustomFilters.includes("radio:" + item.details.radioNumber)
				}
				itemOnChange={(item) => {
					if (
						selectedCustomFilters.includes("radio:" + item.details.radioNumber)
					) {
						setSelectedCustomFilters(
							selectedCustomFilters.filter(
								(data) => data !== "radio:" + item.details.radioNumber
							)
						);
					} else {
						setSelectedCustomFilters([
							...selectedCustomFilters,
							"radio:" + item.details.radioNumber,
						]);
					}
				}}
				itemDisplayerData={selectedCustomFilters
					.filter((filter) => filter.split(":")[0] === "radio")
					.map((el) => {
						return {
							label:
								el.split(/:(.*)/s)[1] === "0"
									? "2.4 GHz"
									: el.split(/:(.*)/s)[1] === "1"
									? "5 GHz"
									: el.split(/:(.*)/s)[1] === "2"
									? "6 GHz"
									: "",
							details: { radioNumber: el.split(/:(.*)/s)[1] },
						};
					})}
				itemDisplayerOnClick={(item) => {
					setSelectedCustomFilters(
						selectedCustomFilters.filter(
							(data) => data !== "radio:" + item.details.radioNumber
						)
					);
				}}
			/>

			<Dropdown
				title={"Clients:"}
				options={networkClients.map((client) => {
					return {
						label: client.mac,
					};
				})}
				noDataAvailableLabel="No Network Clients Available"
				selectPrompt={"Select Network Client(s):"}
				icon={client_device_icon}
				dropup
				searchable
				checkable
				itemChecked={(item) =>
					selectedCustomFilters.includes("clientMac:" + item.label)
				}
				itemOnChange={(item) => {
					if (selectedCustomFilters.includes("clientMac:" + item.label)) {
						setSelectedCustomFilters(
							selectedCustomFilters.filter(
								(data) => data !== "clientMac:" + item.label
							)
						);
					} else {
						setSelectedCustomFilters([
							...selectedCustomFilters,
							"clientMac:" + item.label,
						]);
					}
				}}
				itemDisplayerData={selectedCustomFilters
					.filter((filter) => filter.split(":")[0] === "clientMac")
					.map((el) => {
						return {
							label: el.split(/:(.*)/s)[1],
						};
					})}
				itemDisplayerOnClick={(item) => {
					setSelectedCustomFilters(
						selectedCustomFilters.filter(
							(data) => data !== "clientMac:" + item.label
						)
					);
				}}
			></Dropdown>

			<Dropdown
				title={"Included Network Events:"}
				options={
					networkEventTypes.length &&
					Object.keys(validEventTypes).map((category) => {
						return {
							label: category,
							items: [
								validEventTypes[category].length !== 1 && {
									label: "All " + category,
									checked: () => {
										return (
											!validEventTypes[category].some(
												(eventType) =>
													!selectedIncludedNetworkEventTypes.includes(eventType)
											) && validEventTypes[category].length
										);
									},
									disabled: () => {
										return validEventTypes[category].some((eventType) =>
											selectedExcludedNetworkEventTypes.includes(eventType)
										) || !validEventTypes[category].length
											? "disabled"
											: null;
									},
									onChange: () => {
										// tests if all the event types are selected
										!validEventTypes[category].some(
											(eventType) =>
												!selectedIncludedNetworkEventTypes.includes(eventType)
										)
											? setSelectedIncludedNetworkEventTypes((current) =>
													current.filter(
														(data) => !validEventTypes[category].includes(data)
													)
											  )
											: setSelectedIncludedNetworkEventTypes((current) => [
													...current,
													...validEventTypes[category].filter(
														(eventType) =>
															!selectedIncludedNetworkEventTypes.includes(
																eventType
															)
													),
											  ]);
									},
								},
								...validEventTypes[category].map((item) => {
									return {
										label: item.description,
										details: item,
										checked: (eventType) => {
											return selectedIncludedNetworkEventTypes.some(
												(selectedType) =>
													selectedType.type === eventType.details.type
											);
										},
										disabled: () => {
											return selectedExcludedNetworkEventTypes.includes(item)
												? "disabled"
												: null;
										},
										onChange: (eventType) => {
											selectedIncludedNetworkEventTypes.includes(
												eventType.details
											)
												? setSelectedIncludedNetworkEventTypes((current) =>
														current.filter((data) => data !== eventType.details)
												  )
												: setSelectedIncludedNetworkEventTypes((current) => [
														...current,
														eventType.details,
												  ]);
										},
									};
								}),
							].filter((validate) => validate),
						};
					})
				}
				useCategories
				checkable
				useIndividualInteractions
				useIndividualItemDisplays
				noDataAvailableLabel="No Network Events Available"
				selectPrompt={"Select Network Event(s):"}
				dropup
				icon={include_event_icon}
				//sort list items alphanumericly to avoid missplaced items(needed for the algarithm im using), then if all of the items in the current category are present only return the first one.
				itemDisplayerData={selectedIncludedNetworkEventTypes
					.sort((a, b) => {
						return a.category
							.toString()
							.localeCompare(b.category.toString(), "en", { numeric: true });
					})
					.filter((lookup, i) =>
						!validEventTypes[lookup.category].some(
							(eventType) =>
								!selectedIncludedNetworkEventTypes.includes(eventType)
						)
							? i === 0 ||
							  lookup.category !==
									selectedIncludedNetworkEventTypes[i - 1].category
							: true
					)
					.map((eventType) => {
						if (
							!validEventTypes[eventType.category].some(
								(eventType) =>
									!selectedIncludedNetworkEventTypes.includes(eventType)
							) &&
							validEventTypes[eventType.category].length > 1
						) {
							return {
								label: "All " + eventType.category,
								onClick: () => {
									setSelectedIncludedNetworkEventTypes(
										selectedIncludedNetworkEventTypes.filter(
											(data, index) => data.category !== eventType.category
										)
									);
								},
							};
						}
						return {
							label: eventType.description,
							onClick: () => {
								setSelectedIncludedNetworkEventTypes(
									selectedIncludedNetworkEventTypes.filter(
										(data, index) => data !== eventType
									)
								);
							},
						};
					})}
			></Dropdown>
			<Dropdown
				title={"Excluded Network Events:"}
				options={
					networkEventTypes.length &&
					Object.keys(validEventTypes).map((category) => {
						return {
							label: category,
							items: [
								validEventTypes[category].length !== 1 && {
									label: "All " + category,
									checked: () => {
										return (
											!validEventTypes[category].some(
												(eventType) =>
													!selectedExcludedNetworkEventTypes.includes(eventType)
											) && validEventTypes[category].length
										);
									},
									disabled: () => {
										return validEventTypes[category].some((eventType) =>
											selectedIncludedNetworkEventTypes.includes(eventType)
										) || !validEventTypes[category].length
											? "disabled"
											: null;
									},
									onChange: () => {
										// tests if all the event types are selected
										!validEventTypes[category].some(
											(eventType) =>
												!selectedExcludedNetworkEventTypes.includes(eventType)
										)
											? setSelectedExcludedNetworkEventTypes((current) =>
													current.filter(
														(data) => !validEventTypes[category].includes(data)
													)
											  )
											: setSelectedExcludedNetworkEventTypes((current) => [
													...current,
													...validEventTypes[category].filter(
														(eventType) =>
															!selectedExcludedNetworkEventTypes.includes(
																eventType
															)
													),
											  ]);
									},
								},
								...validEventTypes[category].map((item) => {
									return {
										label: item.description,
										details: item,
										checked: (eventType) => {
											return selectedExcludedNetworkEventTypes.some(
												(selectedType) =>
													selectedType.type === eventType.details.type
											);
										},
										disabled: () => {
											return selectedIncludedNetworkEventTypes.includes(item)
												? "disabled"
												: null;
										},
										onChange: (eventType) => {
											selectedExcludedNetworkEventTypes.includes(
												eventType.details
											)
												? setSelectedExcludedNetworkEventTypes((current) =>
														current.filter((data) => data !== eventType.details)
												  )
												: setSelectedExcludedNetworkEventTypes((current) => [
														...current,
														eventType.details,
												  ]);
										},
									};
								}),
							].filter((validate) => validate),
						};
					})
				}
				useCategories
				checkable
				useIndividualInteractions
				useIndividualItemDisplays
				noDataAvailableLabel="No Network Events Available"
				selectPrompt={"Select Network Event(s):"}
				dropup
				icon={exclude_event_icon}
				//sort list items alphanumericly to avoid missplaced items(needed for the algarithm im using), then if all of the items in the current category are present only return the first one.
				itemDisplayerData={selectedExcludedNetworkEventTypes
					.sort((a, b) => {
						return a.category
							.toString()
							.localeCompare(b.category.toString(), "en", { numeric: true });
					})
					.filter((lookup, i) =>
						!validEventTypes[lookup.category].some(
							(eventType) =>
								!selectedExcludedNetworkEventTypes.includes(eventType)
						)
							? i === 0 ||
							  lookup.category !==
									selectedExcludedNetworkEventTypes[i - 1].category
							: true
					)
					.map((eventType) => {
						if (
							!validEventTypes[eventType.category].some(
								(eventType) =>
									!selectedExcludedNetworkEventTypes.includes(eventType)
							) &&
							validEventTypes[eventType.category].length > 1
						) {
							return {
								label: "All " + eventType.category,
								onClick: () => {
									setSelectedExcludedNetworkEventTypes(
										selectedExcludedNetworkEventTypes.filter(
											(data, index) => data.category !== eventType.category
										)
									);
								},
							};
						}
						return {
							label: eventType.description,
							onClick: () => {
								setSelectedExcludedNetworkEventTypes(
									selectedExcludedNetworkEventTypes.filter(
										(data, index) => data !== eventType
									)
								);
							},
						};
					})}
			></Dropdown>
			<div
				className="Dropdown"
				onMouseEnter={() => {
					setCustomFilterDropdownOpen(true);
				}}
				onMouseLeave={() => {
					setCustomFilterDropdownOpen(false);
				}}
			>
				<img src={custom_filter_icon} className="icon" alt="" />
				<span>Custom Filters:</span>
				<img
					src={info_icon}
					alt=""
					className="moreInfoIcon"
					data-toggle="tooltip"
					title='Format all filters like so "filterName:filterValue" Ex band:5 or channel:6'
				></img>
				<img
					src={arrow_icon}
					className="Arrow"
					alt=""
					style={
						customFilterDropdownOpen ? { transform: "rotate(90deg)" } : null
					}
				/>
				<div
					className="Dropdown-Content"
					style={
						customFilters.length === 0
							? { height: "100%", top: "auto", bottom: "0" }
							: { top: "auto", bottom: "0" }
					}
				>
					{customFilters.length !== 0 ? (
						<div>
							<span>Select Custom Filter(s)</span>
							<hr style={{ marginTop: "0px" }} />
						</div>
					) : (
						<span>0 Filters Available</span>
					)}
					<div>
						<input
							style={{
								width: "75%",
								justifyContent: "center",
								borderRadius: "20px",
								borderWidth: "0px",
							}}
							placeholder="Filter Text"
							onChange={(event) => {
								setNewFilterText(event.target.value);
							}}
							onKeyDown={(e) => {
								if (
									e.key === "Enter" &&
									!customFilters.includes(newFilterText) &&
									newFilterText !== ""
								) {
									setCustomFilters([...customFilters, newFilterText]);
								}
							}}
						/>
						<img
							src={plus_icon}
							className="CustomFilter-CreateButton"
							alt="Create new Filter"
							onClick={() => {
								if (
									!customFilters.includes(newFilterText) &&
									newFilterText !== ""
								) {
									setCustomFilters([...customFilters, newFilterText]);
								}
							}}
						/>
					</div>
					{customFilterDropdownOpen
						? customFilters.length !== 0
							? customFilters.map((filter, i) => {
									return (
										<div key={i}>
											<input
												type="checkbox"
												checked={
													selectedCustomFilters.includes(filter) ? true : false
												}
												onChange={() => {
													selectedCustomFilters.includes(filter)
														? setSelectedCustomFilters(
																selectedCustomFilters.filter(
																	(data, index) => data !== filter
																)
														  )
														: setSelectedCustomFilters([
																...selectedCustomFilters,
																filter,
														  ]);
												}}
											/>
											<span>{filter}</span>
											<img
												src={trashcan_icon}
												className="trashcan_button"
												alt="Delete Filter"
												onClick={() => {
													setCustomFilters(
														customFilters.filter((data) => data !== filter)
													);
													setSelectedCustomFilters(
														selectedCustomFilters.filter(
															(data) => data !== filter
														)
													);
												}}
											/>
										</div>
									);
							  })
							: null
						: null}
				</div>
				<div className="selectedFilterDisplay">
					{selectedCustomFilters.map((filter, i) => {
						if (
							![
								"clientMac",
								"ssidName",
								"deviceSerial",
								"radio",
								"eventLogEndTime",
							].includes(filter.split(/:(.*)/s)[0])
						) {
							return (
								<div key={i} className="filterChoice">
									<span>{filter}</span>
									<span
										className="deselectButton"
										onClick={() => {
											setSelectedCustomFilters(
												selectedCustomFilters.filter(
													(data, index) => data !== filter
												)
											);
										}}
									>
										x
									</span>
								</div>
							);
						}
					})}
				</div>
			</div>
			<Dropdown
				title="Time Zone:"
				searchable
				selectPrompt={"Select Your Time Zone"}
				icon={time_zone_icon}
				options={[
					{
						label: "Local Time:" + -new Date().getTimezoneOffset() / 60 + ":00",
					},
					{ label: "American Samoa:-11:00" },
					{ label: "International Date Line West:-11:00" },
					{ label: "Midway Island:-11:00" },
					{ label: "Hawaii:-10:00" },
					{ label: "Alaska:-09:00" },
					{ label: "Pacific Time (US & Canada):-08:00" },
					{ label: "Tijuana:-08:00" },
					{ label: "Arizona:-07:00" },
					{ label: "Chihuahua:-07:00" },
					{ label: "Mazatlan:-07:00" },
					{ label: "Mountain Time (US & Canada):-07:00" },
					{ label: "Central America:-06:00" },
					{ label: "Central Time (US & Canada):-06:00" },
					{ label: "Guadalajara:-06:00" },
					{ label: "Mexico City:-06:00" },
					{ label: "Monterrey:-06:00" },
					{ label: "Saskatchewan:-06:00" },
					{ label: "Bogota:-05:00" },
					{ label: "Eastern Time (US & Canada):-05:00" },
					{ label: "Indiana (East):-05:00" },
					{ label: "Lima:-05:00" },
					{ label: "Quito:-05:00" },
					{ label: "Caracas:-04:30" },
					{ label: "Atlantic Time (Canada):-04:00" },
					{ label: "Georgetown:-04:00" },
					{ label: "La Paz:-04:00" },
					{ label: "Santiago:-04:00" },
					{ label: "Newfoundland:-03:30" },
					{ label: "Brasilia:-03:00" },
					{ label: "Buenos Aires:-03:00" },
					{ label: "Greenland:-03:00" },
					{ label: "Mid-Atlantic:-02:00" },
					{ label: "Azores:-01:00" },
					{ label: "Cape Verde Is.:-01:00" },
					{ label: "Casablanca:+00:00" },
					{ label: "Dublin:+00:00" },
					{ label: "Edinburgh:+00:00" },
					{ label: "Lisbon:+00:00" },
					{ label: "London:+00:00" },
					{ label: "Monrovia:+00:00" },
					{ label: "UTC:+00:00" },
					{ label: "Amsterdam:+01:00" },
					{ label: "Belgrade:+01:00" },
					{ label: "Berlin:+01:00" },
					{ label: "Bern:+01:00" },
					{ label: "Bratislava:+01:00" },
					{ label: "Brussels:+01:00" },
					{ label: "Budapest:+01:00" },
					{ label: "Copenhagen:+01:00" },
					{ label: "Ljubljana:+01:00" },
					{ label: "Madrid:+01:00" },
					{ label: "Paris:+01:00" },
					{ label: "Prague:+01:00" },
					{ label: "Rome:+01:00" },
					{ label: "Sarajevo:+01:00" },
					{ label: "Skopje:+01:00" },
					{ label: "Stockholm:+01:00" },
					{ label: "Vienna:+01:00" },
					{ label: "Warsaw:+01:00" },
					{ label: "West Central Africa:+01:00" },
					{ label: "Zagreb:+01:00" },
					{ label: "Zurich:+01:00" },
					{ label: "Athens:+02:00" },
					{ label: "Bucharest:+02:00" },
					{ label: "Cairo:+02:00" },
					{ label: "Harare:+02:00" },
					{ label: "Helsinki:+02:00" },
					{ label: "Istanbul:+02:00" },
					{ label: "Jerusalem:+02:00" },
					{ label: "Kyiv:+02:00" },
					{ label: "Pretoria:+02:00" },
					{ label: "Riga:+02:00" },
					{ label: "Sofia:+02:00" },
					{ label: "Tallinn:+02:00" },
					{ label: "Vilnius:+02:00" },
					{ label: "Baghdad:+03:00" },
					{ label: "Kuwait:+03:00" },
					{ label: "Minsk:+03:00" },
					{ label: "Moscow:+03:00" },
					{ label: "Nairobi:+03:00" },
					{ label: "Riyadh:+03:00" },
					{ label: "St. Petersburg:+03:00" },
					{ label: "Volgograd:+03:00" },
					{ label: "Tehran:+03:30" },
					{ label: "Abu Dhabi:+04:00" },
					{ label: "Baku:+04:00" },
					{ label: "Muscat:+04:00" },
					{ label: "Tbilisi:+04:00" },
					{ label: "Yerevan:+04:00" },
					{ label: "Kabul:+04:30" },
					{ label: "Ekaterinburg:+05:00" },
					{ label: "Islamabad:+05:00" },
					{ label: "Karachi:+05:00" },
					{ label: "Tashkent:+05:00" },
					{ label: "Chennai:+05:30" },
					{ label: "Kolkata:+05:30" },
					{ label: "Mumbai:+05:30" },
					{ label: "New Delhi:+05:30" },
					{ label: "Sri Jayawardenepura:+05:30" },
					{ label: "Kathmandu:+05:45" },
					{ label: "Almaty:+06:00" },
					{ label: "Astana:+06:00" },
					{ label: "Dhaka:+06:00" },
					{ label: "Novosibirsk:+06:00" },
					{ label: "Urumqi:+06:00" },
					{ label: "Rangoon:+06:30" },
					{ label: "Bangkok:+07:00" },
					{ label: "Hanoi:+07:00" },
					{ label: "Jakarta:+07:00" },
					{ label: "Krasnoyarsk:+07:00" },
					{ label: "Beijing:+08:00" },
					{ label: "Chongqing:+08:00" },
					{ label: "Hong Kong:+08:00" },
					{ label: "Irkutsk:+08:00" },
					{ label: "Kuala Lumpur:+08:00" },
					{ label: "Perth:+08:00" },
					{ label: "Singapore:+08:00" },
					{ label: "Taipei:+08:00" },
					{ label: "Ulaanbataar:+08:00" },
					{ label: "Osaka:+09:00" },
					{ label: "Sapporo:+09:00" },
					{ label: "Seoul:+09:00" },
					{ label: "Tokyo:+09:00" },
					{ label: "Yakutsk:+09:00" },
					{ label: "Adelaide:+09:30" },
					{ label: "Darwin:+09:30" },
					{ label: "Brisbane:+10:00" },
					{ label: "Canberra:+10:00" },
					{ label: "Guam:+10:00" },
					{ label: "Hobart:+10:00" },
					{ label: "Magadan:+10:00" },
					{ label: "Melbourne:+10:00" },
					{ label: "Port Moresby:+10:00" },
					{ label: "Solomon Is.:+10:00" },
					{ label: "Sydney:+10:00" },
					{ label: "Vladivostok:+10:00" },
					{ label: "New Caledonia:+11:00" },
					{ label: "Auckland:+12:00" },
					{ label: "Fiji:+12:00" },
					{ label: "Kamchatka:+12:00" },
					{ label: "Marshall Is.:+12:00" },
					{ label: "Wellington:+12:00" },
					{ label: "Nuku’alofa:+12:00" },
					{ label: "Samoa:+12:00" },
					{ label: "Tokelau Is.:+13:00" },
				]}
				selectedItem={(item) => {
					return timezone && item.label === timezone.name;
				}}
				itemOnChange={(item) => {
					setTimezone({
						name: item.label,
						offset: parseInt(item.label.split(/:(.*)/s)[1]) * 60,
					});
				}}
				itemDisplayerData={timezone && [{ label: timezone.name }]}
				dropup
			/>
			<div>
				<div className="dateSelector">
					<img src={time_start_icon} className="icon" alt="" />
					<DatePicker
						selected={startingAfter}
						timeInputLabel="Time:"
						showTimeInput
						timeFormat="p"
						dateFormat="yyyy-MM-dd'T'hh:mm:ss"
						placeholderText={"Start Time"}
						onChange={(date) => setStartingAfter(date)}
					/>
				</div>
				<div className="dateSelector">
					<img src={time_stop_icon} className="icon" alt="" />
					<DatePicker
						selected={endingBefore}
						timeInputLabel="Time:"
						showTimeInput
						timeFormat="p"
						dateFormat="yyyy-MM-dd'T'hh:mm:ss"
						placeholderText={"End Time"}
						onChange={(date) => {
							setEndingBefore(date);
							if (startingAfter && date) {
								setSelectedCustomFilters([
									...selectedCustomFilters.filter(
										(lookup) => lookup.split(/:(.*)/s)[0] !== "eventLogEndTime"
									),
									"eventLogEndTime:" + date,
								]);
							}
						}}
					/>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "center",
					marginTop: "24px",
				}}
			>
				<button
					className="button"
					onClick={() => {
						setSelectedCustomFilters([]);
						setSelectedExcludedNetworkEventTypes([]);
						setSelectedIncludedNetworkEventTypes([]);
						setSelectedNetworkClients([]);
						setTimezone({});
					}}
				>
					Clear Filters
				</button>
			</div>
		</div>
	);
};

export default DebugDashboardSideBar;
