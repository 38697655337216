// import React, { useEffect, useState } from 'react'

import { useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import arrow_icon from "./assets/images/arrow_icon.png";
import { css } from "@emotion/css";

const WifiConfigTable = ({ configData, auditFunction }) => {
	// console.log(configData);
	// if (configData) {
	// 	for (const config of configData) {
	// 		const auditResults = auditFunction(config);
	// 		console.log(auditResults);
	// 	}
	// }

	const [collapsed, setCollapsed] = useState(false);
	return (
		<div className="WifiConfigTable">
			<div className="TableTitle">
				<span>Wi-Fi Config</span>
				<img
					src={arrow_icon}
					className="collapseArrow"
					collapsed={collapsed ? "true" : null}
					alt=""
					onClick={() => {
						setCollapsed(!collapsed);
					}}
				/>
			</div>
			<Table
				size="sm"
				striped
				hover
				style={collapsed ? { display: "none" } : {}}
			>
				<thead>
					<tr>
						<th scope="col">Network</th>
						<th scope="col">AP Name</th>
						<th scope="col">Channel</th>
						<th scope="col">Channel Width</th>
						<th scope="col">Tx Power</th>
						<th scope="col">Min. Bitrate</th>
						<th scope="col">Client Balancing</th>
						{/* <th scope="col">SSID Count</th> */}
						<th scope="col">Assigned RF Profile</th>
					</tr>
				</thead>
				<tbody>
					{configData &&
						configData.map((config, i) => {
							const auditResults = auditFunction(config);
							return (
								<tr key={i}>
									<td>{config.networkName}</td>
									<td>{config.deviceName}</td>
									<td>
										<span
											className={css`
												${auditResults.some(
													(lookup) => lookup.type === "channel"
												) && "color: red !important;"}
											`}
										>
											{config.channels}
										</span>
									</td>
									<td>
										{Object.entries(config.channelWidths).map(
											(channelWidth, i) => {
												return (
													<span
														key={i}
														className={css`
															${auditResults.some(
																(lookup) =>
																	lookup.type === "channelWidth" &&
																	lookup.error === channelWidth[0]
															) && "color: red !important;"}
														`}
													>
														{(i !== 0 ? ", " : "") + channelWidth[1]}
													</span>
												);
											}
										)}
									</td>
									<td>
										{Object.entries(config.power).map((power, i) => {
											return (
												<span
													key={i}
													className={css`
														${auditResults.some(
															(lookup) =>
																lookup.type === "power" &&
																lookup.error === power[0]
														) && "color: red !important;"}
													`}
												>
													{(i !== 0 ? ", " : "") + power[1]}
												</span>
											);
										})}
									</td>
									<td>
										{config.minBitrateType === "band" ? (
											Object.entries(config.minBitrate).map((rate, i) => {
												return (
													<span
														key={i}
														className={css`
															${auditResults.some(
																(lookup) =>
																	lookup.type === "minBitrate" &&
																	lookup.error === rate[0]
															) && "color: red !important;"}
														`}
													>
														{(i !== 0 ? ", " : "") + rate[1]}
													</span>
												);
											})
										) : config.minBitrateType === "ssid" ? (
											<span>Per SSID</span>
										) : (
											""
										)}
									</td>
									<td
										className={css`
											${auditResults.some(
												(lookup) => lookup.type === "clientBalancing"
											) && "color: red !important;"}
										`}
									>
										{config.rfProfileId && config.clientBalancingEnabled
											? "On"
											: "Off"}
									</td>
									<td>{config.rfProfileName || config.rfProfileId}</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</div>
	);
};

export default WifiConfigTable;
